//privacy policy
import { Typography, Box } from "@mui/material";
import React from "react";

function PrivacyPolicy() {
    return (
        <Box
            sx={{
                backgroundColor: '#000',
                color: '#fff',
                padding: '2rem',
                minHeight: '100vh',
                maxWidth: '900px',
                margin: '0 auto',
                lineHeight: 1.6,
            }}
        >
            <Typography variant="h4" gutterBottom sx={{ color: '#EECA74', fontWeight: 'bold' }}>
                Terms of Use for Affiliate EGO LLC
            </Typography>

            <Typography component="div" sx={{ whiteSpace: 'pre-line' }}>
                {`
                
                Affiliate EGO LLCs Privacy Policy
 Effective Date: March 19, 2025
Introduction
EGO ("Company," "we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform (the "Service"). By using EGO, you consent to the data practices described in this policy. If you do not agree, please do not use the Service.
Information We Collect
We collect different types of information, including:
Personal Information: Name, email address, phone number, payment details, and other identifying information provided during account registration.
Business Information: Brand details, campaign preferences, and other relevant information shared through our platform.
Usage Data: IP address, browser type, device identifiers, pages viewed, interactions, and other analytics data.
Cookies and Tracking Technologies: We use cookies and similar technologies to enhance user experience and track usage patterns.
How We Use Your Information
We use the collected information to:
Provide, maintain, and improve our Service.
Facilitate connections between brands and influencers.
Process transactions and payments.
Communicate with you about updates, promotions, and support.
Ensure security, prevent fraud, and comply with legal obligations.
How We Share Your Information
We do not sell or rent personal data. However, we may share information with:
Business Partners: Brands and influencers you engage with through EGO.
Service Providers: Third-party vendors helping us operate the platform (e.g., payment processors, analytics providers).
Legal Compliance: When required by law, legal process, or to protect our rights, property, or safety.
Business Transfers: In the event of a merger, sale, or transfer of assets, your data may be included.
Data Security
We implement reasonable security measures to protect your information. However, no online platform is 100% secure. We encourage you to use strong passwords and take precautions to protect your data.
Your Rights and Choices
Depending on your location, you may have the right to:
Access, update, or delete your personal data.
Opt-out of marketing communications.
Restrict or object to data processing.
Withdraw consent where applicable.
To exercise these rights, contact us at [Insert Contact Email].
Third-Party Links and Services
Our Service may contain links to third-party websites. We are not responsible for their privacy practices. Please review their policies before providing any information.
Children's Privacy
EGO is not intended for individuals under 18. We do not knowingly collect data from minors. If you believe we have collected such information, please contact us for removal.
Changes to This Policy
We may update this Privacy Policy from time to time. The updated version will be posted with a revised effective date. Continued use of the Service after changes means you accept the revised policy.
Contact Us
For questions or concerns about this Privacy Policy, contact us at kprobst@affiliateego.com.


                
                `}
            </Typography>
        </Box>
    );
}

export default PrivacyPolicy