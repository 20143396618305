import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    List,
    ListItem,
    ListItemText,
    Chip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    FormLabel,
    Select,
    MenuItem
} from '@mui/material';
import ApplyToJob from './ApplyToJob';

const JobBoard = ({ influencerTags = [] }) => {
    const [jobs, setJobs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null); // Track selected job
    const [filterOpen, setFilterOpen] = useState(false); // Controls filter popup visibility

    // Fetch jobs on mount
    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await fetch('/api/allJobs');
                const data = await response.json();
                console.log(data)
                setJobs(data);
                setFilteredJobs(data);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };
        fetchJobs();
    }, []);

    useEffect(() => {
        const lowerSearchTerm = searchTerm.toLowerCase();
        const filtered = jobs.filter(job =>
            job.title.toLowerCase().includes(lowerSearchTerm) ||
            job.description.toLowerCase().includes(lowerSearchTerm)
        );
        const sortedJobs = filtered.sort((a, b) => {
            const aMatches = a.tags.filter(tag => influencerTags.includes(tag)).length;
            const bMatches = b.tags.filter(tag => influencerTags.includes(tag)).length;
            if (bMatches === aMatches) {
                return new Date(b.date_posted) - new Date(a.date_posted);
            }
            return bMatches - aMatches;
        });
        setFilteredJobs(sortedJobs);
    }, [searchTerm, jobs, influencerTags]);

    if (selectedJob) {
        return (
            <ApplyToJob job={selectedJob} onBack={() => setSelectedJob(null)} />
        );
    }

    // Filter Popup Component (Frontend styling only)
    const FilterPopup = ({ open, onClose }) => {
        // Local state for filter fields (functionality to be added later)
        const [location, setLocation] = useState('');
        const [price, setPrice] = useState('');
        const [completeBy, setCompleteBy] = useState('');
        const [niche, setNiche] = useState('');
        const [jobTags, setJobTags] = useState('');

        return (
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
                <DialogTitle sx={{ color: '#EECA74', backgroundColor: '#121212' }}>
                    Filter Jobs
                </DialogTitle>
                <DialogContent sx={{ backgroundColor: '#121212' }}>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            label="Location"
                            variant="outlined"
                            fullWidth
                            sx={{
                                mb: 2,
                                '& .MuiInputLabel-root': { color: '#FFFFFF' },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: '#EECA74' },
                                    '&:hover fieldset': { borderColor: '#EECA74' },
                                    '&.Mui-focused fieldset': { borderColor: '#EECA74' },
                                    '& input': { color: '#FFFFFF' },
                                }
                            }}
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                        <TextField
                            label="Price"
                            variant="outlined"
                            fullWidth
                            type="number"
                            sx={{
                                mb: 2,
                                '& .MuiInputLabel-root': { color: '#FFFFFF' },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: '#EECA74' },
                                    '&:hover fieldset': { borderColor: '#EECA74' },
                                    '&.Mui-focused fieldset': { borderColor: '#EECA74' },
                                    '& input': { color: '#FFFFFF' },
                                }
                            }}
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                        <TextField
                            label="Complete By Date"
                            variant="outlined"
                            fullWidth
                            type="date"
                            sx={{
                                mb: 2,
                                '& .MuiInputLabel-root': { color: '#FFFFFF' },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: '#EECA74' },
                                    '&:hover fieldset': { borderColor: '#EECA74' },
                                    '&.Mui-focused fieldset': { borderColor: '#EECA74' },
                                    '& input': { color: '#FFFFFF' },
                                    '& input::-webkit-calendar-picker-indicator': { filter: 'invert(1)' }
                                }
                            }}
                            value={completeBy}
                            onChange={(e) => setCompleteBy(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <FormLabel sx={{ color: '#EECA74' }}>Content Area of Focus</FormLabel>
                            <Select
                                value={niche}
                                onChange={(e) => setNiche(e.target.value)}
                                sx={{
                                    color: '#FFFFFF',
                                    '& .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' },
                                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' },
                                    backgroundColor: '#121212',
                                    '& .MuiSelect-icon': { color: '#FFFFFF' }
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Technology">Technology</MenuItem>
                                <MenuItem value="Fashion">Fashion</MenuItem>
                                <MenuItem value="Food">Food</MenuItem>
                                <MenuItem value="Health">Health</MenuItem>
                                <MenuItem value="Travel">Travel</MenuItem>
                                {/* Add more niche options as needed */}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Tags"
                            variant="outlined"
                            fullWidth
                            sx={{
                                mb: 2,
                                '& .MuiInputLabel-root': { color: '#FFFFFF' },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: '#EECA74' },
                                    '&:hover fieldset': { borderColor: '#EECA74' },
                                    '&.Mui-focused fieldset': { borderColor: '#EECA74' },
                                    '& input': { color: '#FFFFFF' },
                                }
                            }}
                            value={jobTags}
                            onChange={(e) => setJobTags(e.target.value)}
                            helperText="Enter tags separated by commas"
                        />
                        {/* Additional filter ideas: Remote options, Experience Level, etc. */}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#121212' }}>
                    <Button onClick={onClose} sx={{ color: '#EECA74' }}>Cancel</Button>
                    <Button onClick={onClose} variant="contained" sx={{ color: '#000000', backgroundColor: '#EECA74' }}>
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <Box sx={{
            maxWidth: 800,
            mx: 'auto',
            p: 2,
            backgroundColor: '#121212',
            borderRadius: '8px',
            boxShadow: 3
        }}>
            <Typography variant="h4" gutterBottom sx={{ color: '#EECA74', textAlign: 'center' }}>
                Job Board
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    label="Search Jobs"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: '#EECA74' },
                            '&:hover fieldset': { borderColor: '#EECA74' },
                            '&.Mui-focused fieldset': { borderColor: '#EECA74' },
                        },
                        input: { color: '#FFFFFF' },
                        label: { color: '#EECA74' }
                    }}
                />
                <Button
                    variant="outlined"
                    sx={{
                        ml: 2,
                        mt: '16px',
                        height: '56px',
                        borderColor: '#EECA74',
                        color: '#EECA74'
                    }}
                    onClick={() => setFilterOpen(true)}
                >
                    Filter
                </Button>
            </Box>
            <List sx={{ mt: 2 }}>
                {filteredJobs.map((job, index) => (
                    <ListItem
                        key={index}
                        divider
                        onClick={() => setSelectedJob(job)}
                        sx={{
                            border: '2px solid #EECA74',
                            borderRadius: '12px',
                            mb: 2,
                            p: 2,
                            backgroundColor: '#1e1e1e',
                            cursor: 'pointer',
                            transition: 'transform 0.2s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.02)',
                                boxShadow: '0px 4px 10px rgba(0,0,0,0.3)'
                            }
                        }}
                    >
                        <ListItemText
                            primary={
                                <Typography variant="h6" sx={{ color: '#EECA74' }}>
                                    {job.title}
                                </Typography>
                            }
                            secondary={
                                <>
                                    <Typography variant="body2" sx={{ color: '#FFFFFF', mt: 1 }}>
                                        {job.description}
                                    </Typography>
                                    <Box sx={{ mt: 1 }}>
                                        {job.tags.map((tag, idx) => (
                                            <Chip
                                                key={idx}
                                                label={tag}
                                                sx={{
                                                    mr: 1,
                                                    mb: 1,
                                                    color: '#000000',
                                                    backgroundColor: '#EECA74',
                                                }}
                                            />
                                        ))}
                                    </Box>
                                </>
                            }
                        />
                    </ListItem>
                ))}
            </List>
            {/* Filter Popup */}
            <FilterPopup open={filterOpen} onClose={() => setFilterOpen(false)} />
        </Box>
    );
};

export default JobBoard;
