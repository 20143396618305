import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    MenuItem,
    Box,
} from '@mui/material';
import { useAuth } from '../../contexts/authContext';

const Socials = ({ open, onClose }) => {
    const { currentUser } = useAuth();
    const [platform, setPlatform] = useState('TikTok'); // default preset
    const [username, setUsername] = useState('');
    const [accountUrl, setAccountUrl] = useState('');
    const [followerCount, setFollowerCount] = useState(''); // new state for follower count

    // When platform or username changes, update the accountUrl if not using "Other"
    useEffect(() => {
        if (platform !== 'Other' && username.trim() !== '') {
            // Ensure username starts with an '@'
            let formattedUsername = username.trim();
            if (!formattedUsername.startsWith('@')) {
                formattedUsername = '@' + formattedUsername;
            }
            // Choose the base URL based on the platform
            let baseUrl = '';
            if (platform === 'TikTok') {
                baseUrl = 'https://www.tiktok.com/';
            } else if (platform === 'Instagram') {
                baseUrl = 'https://www.instagram.com/';
            } else if (platform === 'YouTube') {
                baseUrl = 'https://www.youtube.com/';
            }
            setAccountUrl(baseUrl + formattedUsername);
            // Also update the username to always include '@'
            setUsername(formattedUsername);
        }
    }, [platform, username]);

    const handleSubmit = async () => {
        try {
            // Prepare the payload for insertion into your social_accounts table
            const payload = {
                platform,
                accountUrl,
                username,
                followerCount, // use the user-entered follower count (as text)
            };

            const response = await fetch('/api/add-social', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(payload),
            });
            const data = await response.json();
            if (data.success) {
                alert(`Social account for ${platform} added successfully.`);
                onClose();
            } else {
                alert(`Failed to add social account for ${platform}.`);
            }
        } catch (error) {
            console.error('Error adding social account:', error);
            alert('Error adding social account.');
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    backgroundColor: '#000', // black background
                    color: '#fff',           // white text
                },
            }}
        >
            <DialogTitle>Add Social Account</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
                    {/* Platform selection */}
                    <TextField
                        select
                        label="Platform"
                        value={platform}
                        onChange={(e) => setPlatform(e.target.value)}
                        fullWidth
                        InputLabelProps={{ sx: { color: '#fff' } }}
                        sx={{
                            input: { color: '#fff' },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#fff' },
                            },
                        }}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    sx: {
                                        bgcolor: '#000', // black background for dropdown
                                        color: '#fff',   // white text for items
                                    },
                                },
                            },
                        }}
                    >
                        <MenuItem value="TikTok">TikTok</MenuItem>
                        <MenuItem value="Instagram">Instagram</MenuItem>
                        <MenuItem value="YouTube">YouTube</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </TextField>

                    {/* Username input */}
                    <TextField
                        label="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        fullWidth
                        InputLabelProps={{ sx: { color: '#fff' } }}
                        sx={{
                            input: { color: '#fff' },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#fff' },
                            },
                        }}
                        helperText="The '@' will be added automatically."
                    />

                    {/* Account URL field - always visible */}
                    <TextField
                        label="Account URL"
                        value={accountUrl}
                        onChange={(e) => {
                            // Allow editing only if platform is "Other"
                            if (platform === 'Other') {
                                setAccountUrl(e.target.value);
                            }
                        }}
                        fullWidth
                        disabled={platform !== 'Other'}
                        InputLabelProps={{ sx: { color: '#fff' } }}
                        sx={{
                            input: { color: '#fff' },
                            backgroundColor: '#333', // dark grey background for visibility
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#fff' },
                            },
                        }}
                    />

                    {/* Follower Count input */}
                    <TextField
                        label="Follower Count"
                        value={followerCount}
                        onChange={(e) => setFollowerCount(e.target.value)}
                        fullWidth
                        placeholder="e.g., 10000 or 10k"
                        InputLabelProps={{ sx: { color: '#fff' } }}
                        InputProps={{
                            sx: {
                                backgroundColor: '#000000', // light grey background
                                color: '#ffffff',             // black text inside the input
                            },
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#fff' },
                            },
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ color: '#fff' }}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} sx={{ backgroundColor: 'primary.main', color: '#000' }}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Socials;
