// LandingPage.js
import React from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';
import GetStartedButton from "./GetStartedButton";
import FeatureGrid from "./FeatureGrid";
import backgroundImage from './back.svg';


export default function LandingPage() {
    const initialLaunchFeatures = [
        "Job/Campaign Posting",
        "Chat Feature",
        "Influencer Matching",
        "Analytics",
        "Calendar",
        "In App Payment",
    ];

    const comingSoonFeatures = [
        "AI Driven Feedback",
        "iOS App",
        "Docusign Integration",
        "Influencer Managed Accounts ",
        "AI Content Creation",
        "More on the way...",
    ];

    return (
        <>
            {/* Hero Section Frame */}
            <Container
                maxWidth="lg"
                xs={12}
                sx={{
                    position: 'relative',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#fff',
                    py: 4,
                    overflow: 'hidden',
                }}
            >
                {/* Background Image */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        height: '90%',
                        zIndex: -1,
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                />

                {/* Scalable Content */}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                textAlign: 'center',
                                transform: 'scale(0.9)', // Start smaller and scale
                                '@media (max-width: 1200px)': {
                                    transform: 'scale(0.8)', // Smaller for medium screens
                                },
                                '@media (max-width: 900px)': {
                                    transform: 'scale(0.7)', // Smaller for small screens
                                },
                                '@media (max-width: 600px)': {
                                    transform: 'scale(0.6)', // Smallest for mobile
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 2, // Add space between elements
                            }}
                        >
                            <Typography fontWeight="fontWeightBold" variant="h1" gutterBottom>
                                EGO
                            </Typography>
                            <Typography fontWeight="fontWeightBold" variant="h3" gutterBottom>
                                Engage - Grow - Optimize.
                            </Typography>
                            <Typography fontWeight="fontWeightLight" variant="h5">
                                Everything you need to manage your brand deals.
                            </Typography>
                            {/* Call to action button */}
                            <Grid item xs={12} textAlign="center">
                                <GetStartedButton />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            {/* Mission Section Frame */}
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 8,
                    color: '#FFFFFF',
                    textAlign: 'center'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        width: '100%',
                        mb: 2
                    }}
                >
                    <Box sx={{ flex: 1, height: '2px', backgroundColor: '#EECA74' }} />
                    <Typography variant="h5" sx={{ color: '#FFFFFF' }}>Mission</Typography>
                    <Box sx={{ flex: 1, height: '2px', backgroundColor: '#EECA74' }} />
                </Box>
                <Typography variant="h6" sx={{ maxWidth: '800px', fontWeight: 300 }}>
                    Empowering authentic connections between brands and influencers to drive meaningful impact,
                    build trust, and amplify voices through ethical, creative, and data-driven partnerships.
                </Typography>
            </Container>

            {/* About Us Section Frame */}
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 8,
                    color: '#FFFFFF',
                    textAlign: 'center'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        width: '100%',
                        mb: 2
                    }}
                >
                    <Box sx={{ flex: 1, height: '2px', backgroundColor: '#EECA74' }} />
                    <Typography variant="h5" sx={{ color: '#FFFFFF' }}>About Us</Typography>
                    <Box sx={{ flex: 1, height: '2px', backgroundColor: '#EECA74' }} />
                </Box>
                <Typography variant="h6" sx={{ maxWidth: '800px', fontWeight: 300 }}>
                    <strong>EGO</strong> is a small, passionate startup and influencer marketing platform designed to bring
                    brands and influencers together with purpose and transparency. We simplify campaign management, streamline communication,
                    and enable businesses of all sizes to tap into the power of social influence. Our mission is to make influencer marketing
                    more accessible, efficient, and meaningful — and we’re just getting started!
                </Typography>
                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                    Join us as we grow — and help shape the future of social influence.
                </Typography>
            </Container>



            {/* Initial Launch Features Section */}
            <Container maxWidth="lg">
                <FeatureGrid title="Initial Launch Features" features={initialLaunchFeatures} />
            </Container>

            {/* Coming Soon Features Section */}
            <Container maxWidth="lg">
                <FeatureGrid title="Coming Soon" features={comingSoonFeatures} />
            </Container>
        </>
    );
}
