// NewChatRoomDialog.jsx
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    List,
    ListItem,
    ListItemText
} from '@mui/material';

function NewChatRoomDialog({ open, setOpen, renderTrigger, setRenderTrigger, setSelectedContactId }) {
    const [partnerInput, setPartnerInput] = useState("");
    const [chatPartners, setChatPartners] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState(null);

    // Fetch eligible chat partners (only those with an accepted job)
    useEffect(() => {
        fetch("/api/getChatPartners")
            .then(res => res.json())
            .then(data => {
                setChatPartners(data);
            })
            .catch(err => console.error("Error fetching chat partners:", err));
    }, []);

    // If partner.name exists (company), use that; otherwise, first_name + last_name (influencer).
    const getPartnerDisplayName = (partner) => {
        return partner.name || `${partner.first_name} ${partner.last_name}`;
    };

    // Filter chat partners based on the input
    const filteredChatPartners = chatPartners.filter((partner) => {
        const displayName = getPartnerDisplayName(partner);
        return displayName.toLowerCase().includes(partnerInput.toLowerCase());
    });

    const handleClose = () => {
        setOpen(false);
    };

    // When a partner is selected from the list, store the partner object and update the input.
    const handleSelectPartner = (partner) => {
        setSelectedPartner(partner);
        setPartnerInput(getPartnerDisplayName(partner));
    };

    // Create a new chat room with the selected partner, staying on the same page.
    const handleCreateChatroom = async () => {
        if (!selectedPartner) return;
        handleClose();

        try {
            const response = await fetch('/api/createNewChatRoom', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ invitedUserId: selectedPartner.id }),
            });
            const result = await response.json();

            if (response.ok) {
                // If a chat room is found or created, set that as the selected contact
                if (result.message === "Existing chatroom found" || result.message === "New chatroom created") {
                    // Trigger a re-fetch in Messenger
                    setRenderTrigger(prev => !prev);
                    // Set the newly found/created chat room as selected so messages appear
                    setSelectedContactId(result.chatroomId);
                }
            }
            setPartnerInput('');
            setSelectedPartner(null);
        } catch (error) {
            console.error('Failed to create chat room:', error);
            setPartnerInput('');
            setSelectedPartner(null);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{ sx: { backgroundColor: '#000000', color: '#FFFFFF' } }}
        >
            <DialogTitle sx={{ color: '#FFFFFF' }}>Start a New Chatroom</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="partner"
                    label="Search by name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={partnerInput}
                    onChange={(e) => {
                        setPartnerInput(e.target.value);
                        setSelectedPartner(null);
                    }}
                    placeholder="Type a name..."
                    sx={{
                        mb: 2,
                        '& .MuiInputLabel-root': { color: '#FFFFFF' },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: '#FFFFFF' },
                            '& input': { color: '#FFFFFF' }
                        }
                    }}
                />
                {/* List of available chat partners */}
                <Box sx={{ maxHeight: '200px', overflowY: 'auto', mt: 2 }}>
                    {filteredChatPartners.map((partner) => (
                        <ListItem
                            key={partner.id}
                            button
                            onClick={() => handleSelectPartner(partner)}
                            sx={{ backgroundColor: '#000000' }}
                        >
                            <ListItemText
                                primary={getPartnerDisplayName(partner)}
                                sx={{ color: '#FFFFFF' }}
                            />
                        </ListItem>
                    ))}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} sx={{ color: '#EECA74' }}>Cancel</Button>
                <Button onClick={handleCreateChatroom} sx={{ color: '#EECA74' }}>Create</Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewChatRoomDialog;
