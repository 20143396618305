import React, { useState, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';

function createData(_id, title, description, location, status) {
  return { _id, title, description, location, status };
}

const fetchData = async () => {

  try {
    const response = await fetch('/api/jobs', {
          method: 'GET',
          credentials: 'include',
    });
    if (!response.ok) {
          console.error(`Error fetching jobs: ${response.status} ${response.statusText}`);
          return [];
    }
    const data = await response.json();

    return data.map(job => createData(
        job._id,
        job.title,
        job.description,
        job.location,
        job.status
    ));
  } catch (error) {
    console.error('Error fetching job offers:', error);
    return [];
  }
};

export default function BrandDealTableCompany() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchData().then(setRows);
  }, []);

  return (
      <Box
          sx={{
            margin: 2,
            padding: 2,
            backgroundColor: '#000000', // Black background
            borderRadius: '16px',
            border: '2px solid #EECA74', // Gold border
            color: '#EECA74', // Gold text color
            boxShadow: 3,
          }}
      >
        <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 2 }}
        >
          Company Job Postings
        </Typography>
        <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Table aria-label="company campaign table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#EECA74', fontWeight: 'bold' }}>Job Title</TableCell>
                <TableCell sx={{ color: '#EECA74', fontWeight: 'bold' }}>Job Description</TableCell>
                <TableCell sx={{ color: '#EECA74', fontWeight: 'bold' }}>Location</TableCell>
                <TableCell sx={{ color: '#EECA74', fontWeight: 'bold' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                  rows.map((row) => (
                      <TableRow key={row._id}>
                        <TableCell sx={{ color: '#EECA74' }}>{row.title}</TableCell>
                        <TableCell sx={{ color: '#EECA74' }}>{row.description}</TableCell>
                        <TableCell sx={{ color: '#EECA74' }}>{row.location}</TableCell>
                          <TableCell sx={{ color: '#EECA74' }}>{row.status}</TableCell>
                      </TableRow>
                  ))
              ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center" sx={{ color: '#EECA74' }}>
                      No job postings found
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
  );
}
