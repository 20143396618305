import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const GetStartedButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/register');
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            sx={{
                mt: 2,
                px: 4,
                py: 1,
                fontSize: '1rem',
                backgroundColor: '#EECA74',
                color: '#000',
                '&:hover': {
                    backgroundColor: '#d4b24a',
                },
            }}
        >
            Get Started
        </Button>
    );
};

export default GetStartedButton;
