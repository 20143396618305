import React, { useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, Button } from "@mui/material";
import { supabase } from "../../../supabase/supabaseClient"; // Ensure Supabase is initialized in supabaseClient.js

const ForgotPassword = () => {
    const [openResetDialog, setOpenResetDialog] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSendResetEmail = async (e) => {
        e.preventDefault();
        if (!resetEmail) {
            alert("Please enter your email address in the email field");
            return;
        }

        setLoading(true);
        const { error } = await supabase.auth.resetPasswordForEmail(resetEmail, {
            redirectTo: `${window.location.origin}/update-password`, // Redirect to password reset page
        });

        setLoading(false);

        if (error) {
            console.error("Error sending reset email:", error.message);
            alert(`Failed to send password reset email: ${error.message}`);
        } else {
            alert("Password reset link sent to your email");
            setOpenResetDialog(false);
        }
    };

    const handleResetEmailChange = (event) => {
        setResetEmail(event.target.value);
    };

    const handleForgotPasswordClick = () => {
        setOpenResetDialog(true);
    };

    const handleClose = () => {
        setOpenResetDialog(false);
    };

    return (
        <Box>
            <Typography
                variant="body2"
                textAlign="right"
                sx={{ cursor: 'pointer', color: '#EECA74' }}
                onClick={handleForgotPasswordClick}
            >
                Forgot Password?
            </Typography>
            <Dialog
                open={openResetDialog}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: 'black', // Background color
                        border: '2px solid #EECA74', // Gold border
                        color: '#ffffff', // Text color
                        borderRadius: '12px'
                    }
                }}
            >
                <DialogTitle sx={{ color: '#EECA74', textAlign: 'center' }}>
                    Reset Password
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={resetEmail}
                        onChange={handleResetEmailChange}
                        InputLabelProps={{ style: { color: '#EECA74' } }} // Label color
                        InputProps={{
                            style: { color: '#FFFFFF' }, // Text color
                            sx: {
                                '& .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' }, // Border color
                                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' }, // Hover effect
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' }
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
                    <Button
                        onClick={handleClose}
                        disabled={loading}
                        sx={{ color: '#EECA74', borderColor: '#EECA74', '&:hover': { borderColor: '#EECA74' } }}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSendResetEmail}
                        disabled={loading}
                        sx={{ backgroundColor: '#EECA74', color: 'black', '&:hover': { backgroundColor: '#EECA74' } }}
                        variant="contained"
                    >
                        {loading ? "Sending..." : "Send Reset Email"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ForgotPassword;
