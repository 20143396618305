import { Typography, Box } from "@mui/material";
import React from "react";

function TermsAndServices() {
        return (
            <Box
                sx={{
                        backgroundColor: '#000',
                        color: '#fff',
                        padding: '2rem',
                        minHeight: '100vh',
                        maxWidth: '900px',
                        margin: '0 auto',
                        lineHeight: 1.6,
                }}
            >
                    <Typography variant="h4" gutterBottom sx={{ color: '#EECA74', fontWeight: 'bold' }}>
                            Terms of Use for Affiliate EGO LLC
                    </Typography>

                    <Typography component="div" sx={{ whiteSpace: 'pre-line' }}>
                            {`
                Terms of Use for Affiliate EGO LLC


                Affiliate EGO LLC Terms of Use
                Effective as of March 19, 2025

                Welcome to the Affiliate EGO LLC Terms of Use agreement. For purposes of this agreement, “Site” refers to the Company’s website, which can be accessed at www.affiliateego.com. “Service” refers to the Company’s services accessed via the Site, in which users can connect with brands and complete campaign targets with in the platform. The terms “we,” “us,” and “our” refer to the Company. “You” refers to you, as a user of our Site or our Service.

                The following Terms of Use apply when you view or use the Service www.affiliateego.com.

                Please review the following terms carefully. By accessing or using the Service, you signify your agreement to these Terms of Use. If you do not agree to be bound by these Terms of Use in their entirety, you may not access or use the Service.

                Pre-Formation Business Notice
                Affiliate EGO LLC is currently operating in a pre-formation phase and is in the process of obtaining its official business entity registration. Until our LLC formation is finalized, all users acknowledge and agree that Affiliate EGO LLC operates as a sole proprietorship or other applicable entity. By using our platform, you understand and accept that:
                Limited Liability Disclaimer – While we anticipate completing our LLC registration shortly, until that process is finalized, any legal claims, disputes, or liabilities arising from your use of the platform are acknowledged as being transacted with Affiliate EGO LLC in its current form. You agree not to hold Affiliate EGO LLC’s owners, officers, or agents personally liable for any claims, damages, or disputes.


                Indemnification Clause – By using the platform, you agree to indemnify and hold harmless Affiliate EGO LLC, its affiliates, and representatives from any losses, claims, liabilities, or legal actions related to your use of the platform, including but not limited to disputes between brands and influencers, content violations, and payment processing issues.


                Payment & Chargeback Acknowledgment – Affiliate EGO LLC serves only as a facilitator for transactions between brands and influencers. Users acknowledge that all payments made through the platform are final, except in cases of proven fraud. Chargeback attempts or disputes must go through Affiliate EGO LLC’s internal resolution process before involving third-party financial institutions.


                Contractual Agreement – By accessing or using the platform, you confirm that you understand and accept that Affiliate EGO LLC is in the process of formalizing its LLC status. You agree that any transactions made during this period are with Affiliate EGO LLC as a business entity, rather than its individual owners or representatives.

                Definitions
                "Platform": The Affiliate EGO LLC website and services.
                "User": Any individual or entity using Affiliate EGO LLC, including brands and influencers.
                "Influencer": A user creating and sharing promotional content.
                "Brand": A user seeking influencer marketing opportunities.
                "Campaign": A marketing initiative facilitated through Affiliate EGO LLC.
                "Content": Any media created by influencers or shared by brands.
                PRIVACY POLICY

                The Company respects the privacy of its Service users. Please refer to the Company’s Privacy Policy which explains how we collect, use, and disclose information that pertains to your privacy. When you access or use the Service, you signify your agreement to the Privacy Policy as well as these Terms of Use.


                ABOUT THE SERVICE
                This Terms & Conditions agreement outlines the rights, responsibilities, and expectations for both influencers and brands using the Affiliate EGO LLC platform. Affiliate EGO LLC facilitates connections between brands and influencers for marketing collaborations, providing tools for campaign management, content delivery, and payment processing. By using the platform, both parties agree to adhere to the guidelines, policies, and obligations set forth to ensure a fair, transparent, and effective working relationship. This agreement covers key aspects such as campaign requirements, content ownership, payment terms, compliance with regulations, and dispute resolution to protect the interests of all users.


                REGISTRATION; RULES FOR USER CONDUCT AND USE OF THE SERVICE

                You need to be at least 18 years old and a resident of the United States to register for and use the Service. If Affiliate EGO LLC finds out or has reason to believe that a user is underage, Affiliate EGO LLC reserves the right to immediately suspend or terminate the account without notice.
                If you are a user who signs up for the Service, you will create a personalized account which includes a unique username and a password to access the Service and to receive messages from the Company. You agree to notify us immediately of any unauthorized use of your password and/or account. The Company will not be responsible for any liabilities, losses, or damages arising out of the unauthorized use of your member name, password and/or account.


                USE RESTRICTIONS

                Your permission to use the Site is conditioned upon the following use, posting, and conduct restrictions:
                You agree that you will not under any circumstances:
                ·    access the Service for any purpose other than engaging in campaigns, collaborations, and activities permitted by the platform, including commercial use as an influencer, while complying with these Terms and Conditions,
                ·    collect or harvest any personal data of any user of the Site or the Service
                ·    use the Site or the Service to solicit business unrelated to influencer marketing or to promote competing platforms without our explicit consent;
                ·    distribute any part or parts of the Site or the Service without our explicit written permission (we grant the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of creating publicly-available searchable indices but retain the right to revoke this permission at any time on a general or specific basis);
                ·    use the Service for any unlawful purpose or for the promotion of illegal activities;
                ·    attempt to, or harass, abuse or harm another person or group;
                ·    use another user’s account without permission;
                ·    intentionally allow another unauthorized user to access your account, except in cases where team-based or manager access is explicitly permitted by the platform.;
                ·    provide false or inaccurate information when registering an account;
                ·    interfere or attempt to interfere with the proper functioning of the Service;
                ·    make any automated use of the Site, the Service or the related systems, or take any action that we deem to impose or to potentially impose an unreasonable or disproportionately large load on our servers or network infrastructure;
                ·    bypass any robot exclusion headers or other measures we take to restrict access to the Service, or use any software, technology, or device to scrape, spider, or crawl the Service or harvest or manipulate data;
                ·    circumvent, disable or otherwise interfere with any security-related features of the Service or features that prevent or restrict use or copying of content, or enforce limitations on use of the Service or the content accessible via the Service; or
                ·    publish or link to malicious content of any sort, including that intended to damage or disrupt another user’s browser or computer.


                PAYMENT TERMS
                Affiliate EGO LLC can facilitate payments between brands and influencers through Stripe. Influencers will receive payment only after successfully completing the campaign and obtaining brand approval. Users have the option to process transactions through the Affiliate EGO LLC platform using Stripe, but it is not required. All transactions processed through Stripe must have valid payment information. Processing times may vary, and Affiliate EGO LLC is not responsible for any delays caused by Stripe, banks, or other third-party payment processors. Additionally, Affiliate EGO LLC is not liable for any issues arising from transactions conducted through Stripe.
                Affiliate EGO LLC does not handle payments directly; all transactions are processed through Stripe. However, Affiliate EGO LLC reserves the right to withhold or delay payments through the platform in cases of suspected fraud, policy violations, chargebacks, or if the influencer’s content or actions—whether campaign-related or not—create controversy that makes them unsuitable for the brand. Influencers are responsible for ensuring accurate payment details, as Affiliate EGO LLC is not liable for failed or delayed payments due to incorrect information provided to Stripe.
                Brands will use Stripe services through the Affiliate EGO LLC platform to initiate and complete payments to influencers. Refunds are not guaranteed but may be considered under the following conditions: (1) The influencer fails to meet the agreed-upon deliverables; (2) The influencer violates Affiliate EGO LLC’s Terms of Use or the campaign agreement; (3) The brand and influencer mutually agree to cancel the campaign before completion. Payments should not be completed until the campaign or job is completed and deliverables have been approved.
                To request a refund, brands must submit a refund request through Affiliate EGO LLC’s internal dispute resolution system within 5 business days of campaign completion. Affiliate EGO LLC will review the request and determine eligibility. Approved refunds will be processed back to the original payment method, minus any applicable processing fees.
                Chargeback attempts or payment disputes are handled by Stripe Services. Check Stripe’s chargeback policy for more information. Accounts involved in fraudulent payment activity may be suspended or terminated.
                Users are solely responsible for reporting and paying any taxes associated with their earnings or payments through Affiliate EGO LLC, and it is recommended that they consult a tax professional for guidance.
                Affiliate EGO LLC strictly prohibits the use of its payment system for unauthorized transactions, money laundering, fraudulent activities, or any attempts to bypass platform fees. Off-platform transactions without prior approval are not allowed. Any violations of these terms may result in account suspension, withheld funds, or legal action.

                POSTING AND CONDUCT RESTRICTIONS

                When you create your own personalized account, you may have to provide personal information, payment details, social media accounts, campaign preferences, brand information, content submissions, and analytical data (“User Content”) to the Service. You are solely responsible for the User Content that you post, upload, link to, or otherwise make available via the Service.
                Affiliate EGO LLC does not actively monitor all User Content but reserves the right to monitor, review, remove, or restrict access to content that violates these Terms.
                We grant you permission to use and access the Service, subject to the following express conditions surrounding User Content. You agree that failure to adhere to any of these conditions constitutes a material breach of these Terms.
                By transmitting and submitting any User Content while using the Service, you agree as follows:
                ·    You are solely responsible for your account and the activity that occurs while signed in to or while using your account;
                ·    You will not post information that is malicious, libelous, false, inaccurate, or that violates applicable advertising standards, including failure to disclose sponsored content in compliance with FTC guidelines.;
                ·    You will not post any information that is abusive, threatening, obscene, defamatory, libelous, or racially, sexually, religiously, or otherwise objectionable and offensive;
                ·    When you upload or post User Content to Affiliate EGO LLC, you grant Affiliate EGO LLC a worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, distribute, display, and perform your content solely for the operation, imporvement and promotion of the platform. By submitting User Content to EGO, you grant Affiliate EGO LLC a perpetual, worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, modify, adapt, publish, distribute, and display your User Content in connection with the operation, promotion, and marketing of EGO’s services. This includes, but is not limited to, displaying content on the platform, sharing it on EGO’s social media accounts, and using it in promotional materials. You also grant other users a limited, non-exclusive, royalty-free license to view and engage with your User Content as permitted under these Terms.
                ·  You will not submit content that is copyrighted or subject to third party proprietary rights, including privacy, publicity, trade secret, or others, unless you are the owner of such rights or have the appropriate permission from their rightful owner to specifically submit such content to Affiliate EGO LLC; and
                ·    Affiliate EGO LLC reserves the right to determine whether User Content violates these Terms and take appropriate action, including removal of content and account termination, with or without prior notice.

                You understand and agree that any liability, loss or damage that occurs as a result of the use of any User Content that you make available or access through your use of the Service is solely your responsibility. Affiliate EGO LLC is not responsible for any public display or misuse of your User Content.
                Affiliate EGO LLC does not pre-screen all User Content but reserves the right, at its discretion, to monitor, review, and take appropriate action regarding User Content.
                Users may report content they believe violates these Terms through Affiliate EGO LLC’s reporting system. Affiliate EGO LLC reserves the right to review and take action, but does not guarantee the removal of reported content.
                Campaign Guidelines and Obligations
                Influencers must adhere to all campaign requirements set by the brand, including content format, messaging, posting schedule, and platform specifications. All content must be original, high-quality, and aligned with the brand’s values and objectives, plagiarized, or misleading content is strictly prohibited. Failure to meet these guidelines, including non-compliance with requested revisions or delayed submissions, may result in non-payment or campaign termination. Some campaigns may require pre-approval of content, and influencers must complete any necessary revisions within the specified timeframe. Additionally, influencers are responsible for complying with all applicable laws and regulations, including FTC guidelines on sponsored content and social media advertising, ensuring that disclosures such as #ad or #sponsored are clearly included. While influencers retain ownership of their content, they grant the brand and Affiliate EGO LLC a limited, non-exclusive, royalty-free license to use, reproduce, or promote the content for marketing purposes, with any specific content usage rights outlined in the campaign agreement.
                Influencers are strictly prohibited from posting content that is defamatory, offensive, illegal, or otherwise harmful to the brand’s reputation, including hate speech, violence, discrimination, or false information. Violations may result in payment withholding, account suspension, or legal action. Authentic engagement is essential, and influencers may not engage in fraudulent activities such as purchasing likes, comments, or followers, as any detected manipulation may lead to removal from the platform and forfeiture of earnings. Some campaigns may also require influencers to refrain from promoting competitors for a specified period, with exclusivity terms outlined in the campaign agreement. Brands and Affiliate EGO LLC reserve the right to terminate an influencer’s participation in a campaign if they fail to meet obligations, violate guidelines, or engage in conduct that harms the brand’s reputation, with payment potentially withheld at Affiliate EGO LLC’s discretion.
                Brands must provide clear and detailed campaign instructions upfront, including content guidelines, messaging requirements, and any specific creative expectations. To ensure a fair and efficient revision process, brands are allowed a maximum of two rounds of revisions. Each revision request can include multiple change requests, but brands may only submit content back to the influencer twice for adjustments. This means that at most, an influencer will submit three versions of the content for approval. If the brand is still unsatisfied after the second revision round, they must either proceed with the final submitted version or opt to terminate the collaboration as outlined in the campaign agreement.
                ONLINE CONTENT DISCLAIMER

                Opinions, advice, statements, offers, or other content made available through Affiliate EGO LLC, but not directly by Affiliate EGO LLC, belong solely to their respective authors. Affiliate EGO LLC does not endorse, verify, or take responsibility for such content.

                Affiliate EGO LLC does not guarantee the accuracy, completeness, or usefulness of any information on the platform. We do not endorse or take responsibility for opinions, advice, or statements made by users or third parties. Affiliate EGO LLC is not responsible for any User Content posted, shared, or sent by users or third parties through the platform. Affiliate EGO LLC is not liable for any loss or damage resulting from reliance on content posted or shared on the platform.

                While we enforce these Terms, users may encounter inaccurate, misleading, or objectionable content. Affiliate EGO LLC may, but is not obligated to, monitor public areas of the platform and take action against content that violates these Terms, infringes rights, or is unlawful, offensive, abusive, or harmful. [Private messages between users will be treated as confidential to the extent required by law, but Affiliate EGO LLC reserves the right to review them if required for legal compliance or safety enforcement.]Affiliate EGO LLC may remove content at its discretion if it violates the law, these Terms, or poses harm to users or the platform.  Unauthorized use of Affiliate EGO LLC may result in legal action, including criminal or civil prosecution under applicable laws. To report violations of these Terms, contact us at kprobst@affiliateego.com. Affiliate EGO LLC reserves the right to review and take action at its discretion.

                LINKS TO OTHER SITES AND/OR MATERIALS

                Affiliate EGO LLC may provide links to third-party websites (“Third-Party Sites”), such as Instagram, TikTok, and YouTube, as well as third-party content, applications, or software (“Third-Party Applications, Software, or Content”). Affiliate EGO LLC does not control Third Party Sites, their content, or any materials, promotions, goods, or services they offer. Affiliate EGO LLC does not investigate, monitor, or verify the accuracy, appropriateness, or completeness of Third Party Sites or their content. We are not responsible for any Third Party Sites accessed through Affiliate EGO LLC or third-party content available on or installed from Affiliate EGO LLC, including their content, accuracy, reliability, opinions, or privacy policies.Providing links to or allowing the use of Third Party Sites or content does not imply Affiliate EGO LLC’s endorsement or approval.
                If you choose to access Third Party Sites or install third-party content, you do so at your own risk. Affiliate EGO LLC’s Terms of Use and policies no longer apply once you leave the platform.
                Review the terms, privacy policies, and data practices of any Third Party Site or application you access through Affiliate EGO LLC.

                COPYRIGHT COMPLAINTS AND COPYRIGHT AGENT

                (a) Termination of Repeat infringement Accounts.
                Affiliate EGO LLC respects the intellectual property rights of others and expects users to do the same. In accordance with 17 U.S.C. 512(i) of the U.S. Copyright Act, we have implemented a policy to terminate accounts of repeat infringers in appropriate circumstances. Users who repeatedly post or distribute protected content without proper authorization may have their access revoked.

                (b) DMCA Take-Down Notices.
                If you are a copyright owner or an authorized agent and believe that content on Affiliate EGO LLC infringes upon your copyright, you may submit a DMCA notification under 17 U.S.C. 512 by providing the following details in writing to our designated copyright agent at 138 E 12300 S, Ste C #502, Draper, UT 84020.

                Date of notification.
                Your physical or electronic signature or that of an authorized agent.
                A description of the copyrighted work you claim is being infringed. If multiple works are affected, provide a list.
                A description of the allegedly infringing material, including where it is located on Affiliate EGO LLC, so we can find and remove it.
                Your contact information, including your address, phone number, and email.
                A statement confirming your good faith belief that the use of the content is unauthorized.
                A statement, under penalty of perjury, that the information in your notice is accurate and that you are authorized to act on behalf of the copyright owner.

                (c) Counter-Notices.
                If you believe that your removed User Content does not infringe copyright or that you have the right to use it under authorization or law, you may submit a counter-notice to our copyright agent, including:

                Your physical or electronic signature.
                A description of the removed content and its location before removal.
                A statement confirming your good faith belief that the content was removed due to a mistake or misidentification.
                Your contact details (name, address, phone, email), a statement consenting to the jurisdiction of the federal court in Utah, and a statement agreeing to accept legal service from the copyright claimant.

                If we receive a counter-notice, we may forward it to the original claimant. If the claimant does not initiate legal action within ten (10) business days, Affiliate EGO LLC may, at its sole discretion, reinstate the content within ten (10) to fourteen (14) business days or longer.

                LICENSE GRANT
                By posting any User Content via the Service, you grant Affiliate EGO LLC a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, adapt, publish, distribute, publicly display, and create derivative works of such User Content solely for the purpose of operating, promoting, and improving the Service. This license is sublicensable and transferable only as necessary to provide the Service.
                You represent and warrant that you have the rights necessary to grant this license. If your User Content includes your name, voice, likeness, or other identifiable attributes, you grant us permission to use them only in connection with the Service and its promotion. This license lasts only as long as your User Content is available on the Service, unless otherwise required by law.
                INTELLECTUAL PROPERTY
                You acknowledge and agree that Affiliate EGO LLC and its affiliates retain all rights, title, and interest in and to the Service, including but not limited to its software, design, branding, logos, trademarks, copyrights, and other proprietary materials. These are protected by intellectual property laws and may not be copied, modified, or used without prior written consent from Affiliate EGO LLC.
                Other trademarks, product names, and company names mentioned on the Service belong to their respective owners. Their appearance on the Service does not imply endorsement or affiliation unless explicitly stated.
                Except for the limited rights granted under these Terms, all rights in and to the Service are reserved by Affiliate EGO LLC.
                EMAIL MAY NOT BE USED TO PROVIDE NOTICE
                Communications made through the Service, including emails, direct messages, and any other messaging features, do not constitute legal notice to Affiliate EGO LLC, its affiliates, officers, employees, agents, or representatives in any case where formal legal notice is required by contract, law, or regulation.
                For legal notices, users must follow the designated procedures outlined in these Terms or send written correspondence to Affiliate EGO LLC’s registered legal address at 138 E 12300 S, Ste C #502, Draper, UT 84020.

                LEGAL NOTICE
                Communications made through Affiliate EGO LLC’s Service, including but not limited to emails, direct messages, or any other messaging features, do not constitute legal notice to Affiliate EGO LLC, its affiliates, officers, employees, agents, or representatives in any case where formal legal notice is required by contract, law, or regulation.
                To provide formal legal notice to Affiliate EGO LLC, you must send a written notice to Affiliate EGO LLC’s designated legal address:
                138 E 12300 S, Ste C #502, Draper, UT 84020
                kprobst@affiliateego.com
                Legal notices must be:
                In writing and physically or electronically signed by the notifying party.
                Sent via a recognized mail carrier (such as USPS, FedEx, or UPS) or email to the designated legal contact kprobst@affiliateego.com.
                Clearly marked as a "Legal Notice" in the subject line or envelope.
                Include sufficient details to identify the matter, including your full name, contact information, and the nature of your legal claim or concern.
                Affiliate EGO LLC’s failure to respond to any communication that does not follow the formal notice requirements shall not be considered a waiver of any rights, defenses, or legal positions.
                Legal Fees & Costs
                If you initiate any lawsuit, claim, or legal action against Affiliate EGO LLC and do not prevail, you agree to pay all legal fees, court costs, and other expenses incurred by EGO in defending against such action. This includes but is not limited to, attorneys' fees, filing fees, expert witness costs, and administrative expenses. This clause applies to all disputes arising from or related to your use of the platform, services, or agreements with EGO.

                USER CONSENT TO RECEIVE COMMUNICATIONS IN ELECTRONIC FORM
                For contractual purposes, you: (a) consent to receive communications from us in an electronic form via the email address associated with your account; and (b) agree that all Terms of Use, agreements, notices, disclosures, and other communications we provide electronically satisfy any legal requirement that such communications would satisfy if provided in writing. This does not affect any non-waivable legal rights you may have.
                We may also use your email address to send you service updates, platform-related notices, and promotional offers. You can opt out of marketing emails by adjusting your account settings, using the 'Unsubscribe' link in the message, or by emailing us atkprobst@affiliateego.com. Please note that opting out of promotional emails will not affect our ability to send you important service-related messages (e.g., security alerts, account notifications).
                WARRANTY DISCLAIMER
                THE SERVICE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, ACCURACY, AND NON-INFRINGEMENT.
                WE DO NOT GUARANTEE THAT ACCESS TO OR OPERATION OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE. YOU ASSUME FULL RESPONSIBILITY FOR ANY RISK OF LOSS RESULTING FROM YOUR DOWNLOADING OR USE OF FILES, INFORMATION, CONTENT, OR OTHER MATERIAL OBTAINED THROUGH THE SERVICE.
                SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES. IN SUCH CASES, OUR LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.

                LIMITATION OF DAMAGES; RELEASE
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE SITE, THE SERVICE, ITS AFFILIATES, DIRECTORS, EMPLOYEES, LICENSORS, OR PARTNERS BE LIABLE TO YOU FOR ANY LOSS OF PROFITS, USE, OR DATA, OR FOR ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, HOWEVER ARISING, THAT RESULT FROM:
                (A) THE USE, DISCLOSURE, OR DISPLAY OF YOUR USER CONTENT;
                (B) YOUR USE OR INABILITY TO USE THE SERVICE;
                (C) THE SERVICE GENERALLY OR THE SOFTWARE OR SYSTEMS THAT MAKE THE SERVICE AVAILABLE; OR
                (D) ANY OTHER INTERACTIONS WITH US OR WITH ANY OTHER USER OF THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE.
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IN SUCH CASES, OUR LIABILITY SHALL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.
                If you have a dispute with one or more users or a business using the service, you release us (and our officers, directors, agents, subsidiaries, joint ventures, and employees) from claims, demands, and damages (both actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.

                If you are a california resident, you expressly waive california civil code §1542, which states: “a general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor.”


                MODIFICATION OF TERMS OF USE
                We reserve the right to amend these Terms of Use at any time. If we make material changes—such as changes affecting your rights, fees, or dispute resolution processes—we will notify you by posting a notice on our homepage and/or sending an email to the address you provided upon registration.
                It is your responsibility to check the Site periodically for updates. Your continued use of the Site or the Service after any amendments constitutes your acceptance of the updated Terms.
                No changes to these Terms (other than as outlined in this section) or waivers of our rights shall be valid unless agreed upon in a written or electronic agreement signed by one of our authorized officers. Verbal, telephonic, or email communications do not constitute valid modifications to these Terms.

                GENERAL TERMS
                If any provision of these Terms of Use is found to be invalid or unenforceable, that portion will be interpreted in a way that aligns with applicable law, while the remaining provisions will continue in full force and effect.
                Failure to enforce any right or provision under this agreement does not constitute a waiver of such right or provision. Our rights under this agreement will survive any termination or transfer of these Terms.
                You agree that any legal claim or cause of action related to Affiliate EGO LLC must be initiated within one (1) year of the date the issue arises. If not, the claim is permanently barred.
                These Terms of Use and your use of the Site are governed by the federal laws of the United States and the laws of the State of Utah, without regard to conflict of law principles.
                We may assign or delegate our rights and obligations under these Terms of Use and our Privacy Policy at any time, with or without notice. You may not transfer your rights or obligations without our prior written consent. Any unauthorized transfer by you is void.
                BY USING THE SITE AND SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THESE TERMS OF USE. THESE TERMS, TOGETHER WITH OUR PRIVACY POLICY, REPRESENT THE COMPLETE AGREEMENT BETWEEN YOU AND Affiliate EGO LLC, SUPERSEDING ANY PRIOR AGREEMENTS OR COMMUNICATIONS.


                        `}
                </Typography>
            </Box>
        );
}

export default TermsAndServices