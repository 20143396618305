import React, {useState} from 'react'
import {doSignInWithEmailAndPassword, doSignInWithGoogle} from '../../../firebase/auth'
import {useAuth} from '../../../contexts/authContext'
import {Box, Typography, TextField, Button, Link, Stack} from '@mui/material';
import ForgotPassword from "../forgot_password/index";
import {useNavigate} from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const {setCurrentUser, currentUser } = useAuth(); // Get currentUser from context
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!isSigningIn) {
            setIsSigningIn(true);
            try {
                const response = await fetch('/api/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, password }),
                    credentials: 'include'
                });


                if (response.ok) {
                    const { user } = await response.json();
                    setCurrentUser(user); // Set currentUser here
                    //console.log("CurrentUser in login: ", user)
                    navigate('/home');
                } else {
                    const errorData = await response.json();
                    alert(`Login failed\nError: ${errorData.message}`);
                }
            } catch (err) {
                alert(`Login failed\nError: ${err.message}`);
            } finally {
                setIsSigningIn(false);
            }
        }
    };

    const onGoogleSignIn = async (e) => {
        e.preventDefault();
        if (!isSigningIn) {
            setIsSigningIn(true);
            try {
                const user = await doSignInWithGoogle(); // Assume this function returns the user object
                setCurrentUser(user); // Set currentUser after Google sign-in
                navigate('/home');
            } catch (err) {
                alert(`Google login failed\nError: ${err.code}`);
            } finally {
                setIsSigningIn(false);
            }
        }
    };



    return (
        <>
            {currentUser ? (
                <Stack spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h3" fontWeight="bold">You are already logged in.</Typography>
                    <Typography variant="h6" fontWeight="light">
                        Navigate to the home screen. {' '}
                        <Link onClick={() => navigate("/home")} underline="hover">home</Link>
                    </Typography>
                </Stack>
            ) : (
                <Box sx={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box
                        sx={{
                            width: 384,
                            p: 4,
                            boxShadow: 3,
                            border: 1,
                            borderRadius: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        <Typography variant="h5" textAlign="center" fontWeight="bold">Welcome Back</Typography>
                        <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <TextField
                                label="Email"
                                type="email"
                                autoComplete="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    style: { color: '#EECA74' }, // Label color
                                }}
                                InputProps={{
                                    style: { color: '#FFFFFF' }, // Text color
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#FFFFFF', // Default border color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#EECA74', // Hover border color
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#EECA74', // Focused border color
                                        },
                                    },
                                }}
                            />
                            <TextField
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    style: { color: '#EECA74' }, // Label color
                                }}
                                InputProps={{
                                    style: { color: '#FFFFFF' }, // Text color
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#FFFFFF', // Default border color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#EECA74', // Hover border color
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#EECA74', // Focused border color
                                        },
                                    },
                                }}
                            />
                            <ForgotPassword />
                            {errorMessage && (
                                <Typography color="error" fontWeight="bold">{errorMessage}</Typography>
                            )}
                            <Button type="submit" variant="contained" color="primary" disabled={isSigningIn} fullWidth>
                                {isSigningIn ? 'Signing In...' : 'Sign In'}
                            </Button>
                        </Box>
                        <Typography variant="body2" textAlign="center">
                            Don't have an account?{' '}
                            <Link onClick={() => navigate("/register")}>Sign up</Link>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                            <Box sx={{ flexGrow: 1, height: 1, bgcolor: 'divider' }} />
                            <Box sx={{ mx: 2, fontWeight: 'bold' }}>OR</Box>
                            <Box sx={{ flexGrow: 1, height: 1, bgcolor: 'divider' }} />
                        </Box>
                        <Button
                            onClick={(e) => onGoogleSignIn(e)}
                            disabled={true}  // Disables the button
                            variant="outlined"
                            fullWidth
                            sx={{
                                mt: 1,
                                py: 1.25,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 1,
                                backgroundColor: "#555555",  // Darker grey background
                                color: "#999999",  // Lighter grey text
                                borderColor: "#777777",  // Grey border
                                opacity: 0.7,  // Make it appear slightly disabled
                                cursor: "not-allowed",  // Prevent interaction
                                '&:hover': {
                                    backgroundColor: "#555555",  // Maintain the same grey on hover
                                    borderColor: "#777777",
                                }
                            }}
                        >
                            Continue with Google
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
};



export default Login