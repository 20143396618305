import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const SuccessPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // Optionally parse session_id from the query string if needed
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');

    return (
        <Box sx={{ p: 3, textAlign: 'center', backgroundColor: '#121212', color: '#FFFFFF', minHeight: '100vh' }}>
            <Typography variant="h4" sx={{ color: '#EECA74', mb: 2 }}>
                Thank You!
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                Your subscription has been successfully activated.
            </Typography>
            <Button variant="contained" sx={{ backgroundColor: '#EECA74', color: '#000000' }} onClick={() => navigate('/')}>
                Go Home
            </Button>
        </Box>
    );
};

export default SuccessPage;
