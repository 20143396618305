// <--------------- App.js - Contains all routes used within react enviroment --------------->
// App.js is the main file that contains all the routes used within the react enviroment.
// It is responsible for handling the different routes and rendering the appropriate components.
// Important Note: Any new pages or components that are created must be added to the routes in order to be used.
// For further details, see the routes below.

import Login from "./components/auth/login";
import Register from "./components/auth/register";

import Header from "./components/header";
import Home from "./components/home";
import ProfilePage from "./components/profile_page";
import ProtectedRoute from "./components/auth/login/ProtectedRoute";
import { AuthProvider } from "./contexts/authContext";
import { useRoutes } from "react-router-dom";
import { Box } from "@mui/material";
import LandingPage from "./components/landing_page/LandingPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import JobManagerPage from "./pages/job-manager/JobManagerPage";
import JobOffersPage from "./components/JobOffersPage";
import CalendarPage from "./components/calendar/CalendarPage";
import CampaignPage from "./components/campaign/CampaignPage";
import JobEditForm from "./pages/job-manager/components/EditJobForm"
import JobBoard from "./components/jobBoard/JobBoard";
import CustomStepper from "./components/newUserProcess/CustomStepper";
import CssBaseline from '@mui/material/CssBaseline';  // Import CssBaseline
import ContactPage from "./components/Contact/ContactPage";
import React, { useEffect } from "react";
import { GlobalStyles } from '@mui/material';
import InfluencerSearch from "./components/influencerBoard/InfluencerSearch";
import Billing from "./components/billing/Billing";
import Success from "./components/billing/SuccessPage";
import Cancel from "./components/billing/CancelPage";
import UpdatePassword from "./components/auth/forgot_password/UpdatePassword";



function App() {
    const theme = createTheme({
        palette: {
            // Base colors
            background: {
                default: '#000000',
                //default: '#f5f5f5', // A light, neutral background
            },
            primary: {
                main: '#EECA74'
                //main: '#f4cd2a', //first gold
             //   main: '#8e44ad', // A bold, distinctive purple for accents
            },
            secondary: {
                main: '#EECA74'
                //main: '#e49ea9'
                //main: '#34495e', // A deep, rich blue for contrasting elements
            },
            text: {
                primary: '#FFFFFF',
                secondary: '#FFFFFF'
            },
            // Additional colors as needed
        },
        typography: {
            // Custom typography for that unique hipster feel
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeightLight: 400,
            fontWeightRegular: 500,
            fontWeightMedium: 600,
            fontWeightBold: 700,
            allVariants: {
                color: '#FFFFFF', // All text will default to white unless overridden
            },
            // Add more styles as needed
        },
        // Additional theming options as needed
    });

    useEffect(() => {
        //console.log('Supabase URL in App.js:', process.env.REACT_APP_SUPABASE_URL);
        //console.log('Supabase Anon Key in App.js:', process.env.REACT_APP_SUPABASE_ANON_KEY);
    }, []);

    const routesArray = [
        {
            path: "*",
            element: <LandingPage />,
        },
        {
            path: "/contact",
            element: <ContactPage />,  // New route for the Contact page
        },
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/register",
            element: <Register />,
        },
        {
            path: "/CustomStepper",  // Path for the landing page
            element: <CustomStepper />,  // Your landing page component
        },

        {
            path: "/home",
            element: (
                <ProtectedRoute>
                    <Home />
                </ProtectedRoute>
            )
        },
        {
            path: "/profile_page",
            element: (
                <ProtectedRoute>
                    <ProfilePage />
                </ProtectedRoute>
            )
        },
        {
            path: "/job-manager/components",
            element: (
                <ProtectedRoute>
                    <JobManagerPage />
                </ProtectedRoute>
            )
        },
        {
            path: "/jobofferspage",
            element: (
                <ProtectedRoute>
                    <JobOffersPage />
                </ProtectedRoute>
            )
        },
        {
            path: "/campaign",
            element: (
                <ProtectedRoute>
                    <CampaignPage />
                </ProtectedRoute>
            )
        },
        {
            path: "/calendar",
            element: (
                <ProtectedRoute>
                    <CalendarPage />
                </ProtectedRoute>
            ) // Add this route
        },

        {
            path: "/jobboard",
            element: (
                <ProtectedRoute>
                    <JobBoard />
                </ProtectedRoute>
            ) // New route for the Job Board
        },
        {
            path: "/job-manager/components/EditJobForm",
            element: (
                <ProtectedRoute>
                    <JobEditForm />
                </ProtectedRoute>
            )
        },
        {
            path: "/influencer-search",
            element: (
                <ProtectedRoute>
                    <InfluencerSearch />
                </ProtectedRoute>
            )
        },
        {
            path: "/billing",
            element: (
                <ProtectedRoute>
                    <Billing />
                </ProtectedRoute>
            )
        },
        {
            path:"/success",
            element: <Success />,
        },
        {
            path:"/cancel",
            element: <Cancel />,
        },
        {
            path:"/update-password",
            element: <UpdatePassword />,
        }
    ];

    let routesElement = useRoutes(routesArray);

    return (
        <>
            {/* GlobalStyles for overriding third-party component styles */}
            <GlobalStyles styles={{
                '.pac-container': {
                    zIndex: '9999 !important',
                },
                '.rbc-today': {
                    backgroundColor: '#000000 !important', // Black background for current day
                    border: '2px solid #EECA74 !important'     // Gold border around current day
                },
                '.rbc-off-range-bg': {
                    backgroundColor: '#333333 !important'      // Dark grey for off-range days
                }
            }} />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthProvider>
                    <Header />
                    <Box
                        sx={{
                            width: '100%',
                            height: '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingTop: "150px"
                        }}
                    >
                        {routesElement}
                    </Box>
                </AuthProvider>
            </ThemeProvider>
        </>
    );
}

export default App;