import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material';

const AcceptedJobDetails = ({ job, onBack, onJobCompleted }) => {
    const [loading, setLoading] = useState(false);
    const [jobMapData, setJobMapData] = useState(null);
    const [fetchingStatus, setFetchingStatus] = useState(false);

    // Function to fetch the updated job mapping status.
    const fetchCompletionStatus = async () => {
        if (!job || !job.job_id) return;
        setFetchingStatus(true);
        try {
            const response = await fetch(`/api/getCompletionStatus?jobId=${job.job_id}`, {
                credentials: 'include',
            });
            if (response.ok) {
                const compData = await response.json();
                // Assume compData.data contains { status, influencer_completed }
                setJobMapData(compData.data);
            } else {
                console.error('Failed to fetch job map status');
            }
        } catch (error) {
            console.error('Error fetching job map status:', error);
        } finally {
            setFetchingStatus(false);
        }
    };

    // Fetch initial status when the component mounts or job.job_id changes.
    useEffect(() => {
        fetchCompletionStatus();
    }, [job?.job_id]);

    const handleMarkAsCompleted = async () => {
        if (!job || !job.job_id) {
            alert('No job selected.');
            return;
        }
        setLoading(true);
        try {
            const response = await fetch('/api/completeJobForInfluencer', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ jobId: job.job_id }),
            });
            const data = await response.json();
            if (response.ok) {
                alert(data.message);
                // Refresh the job mapping status in this component.
                fetchCompletionStatus();
                // Trigger parent's refresh callback to update the job offers list.
                if (onJobCompleted) onJobCompleted();
            } else {
                alert(`Error: ${data.message}`);
            }
        } catch (error) {
            console.error('Error marking job as completed:', error);
            alert('Error marking job as completed.');
        } finally {
            setLoading(false);
        }
    };

    if (!job) {
        return (
            <Box sx={{ mt: 2 }}>
                <Typography variant="h6" textAlign="center">
                    No job details available.
                </Typography>
            </Box>
        );
    }

    // Use fetched job mapping data if available; fall back to the original job prop.
    const finalStatus = jobMapData ? jobMapData.status : job.status;
    const finalInfluencerCompleted = jobMapData ? jobMapData.influencer_completed : job.influencer_completed;

    // Determine the button's disabled state and label based on the job (or job mapping) status.
    let isButtonDisabled = false;
    let buttonLabel = 'Mark as Completed';

    if (finalStatus === 'pending') {
        isButtonDisabled = true;
        buttonLabel = 'Pending Acceptance';
    } else if (finalStatus === 'pending_completion') {
        if (finalInfluencerCompleted) {
            isButtonDisabled = true;
            buttonLabel = 'Pending Completion';
        } else {
            isButtonDisabled = false;
            buttonLabel = 'Mark as Completed';
        }
    } else if (finalStatus === 'completed') {
        isButtonDisabled = true;
        buttonLabel = 'Completed';
    }

    // Define button styles.
    const activeButtonSx = { backgroundColor: '#EECA74', color: '#000000' };
    const disabledButtonSx = { backgroundColor: '#9e9e9e', color: '#fff', border: '1px solid #757575' };

    // Helper function to render the compensation info.
    const renderCompensation = () => {
        if (!job.compensation) return 'Not specified';
        const { type, value } = job.compensation;
        if (!type || !value) return 'Not specified';

        switch (type) {
            case 'both':
                return `Dollar + Other Compensation: ${value}`;
            case 'dollar':
                return `Dollar Compensation: ${value}`;
            case 'other':
                return `Other Compensation: ${value}`;
            default:
                return 'Not specified';
        }
    };

    return (
        <Box sx={{ mt: 2, px: 2 }}>
            <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
                Accepted Job Details
            </Typography>
            <Card variant="outlined" sx={{ backgroundColor: 'black', color: 'white' }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        {job.title || 'Untitled Job'}
                    </Typography>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: '#EECA74', borderBottom: 'none', fontWeight: 'bold' }}>
                                    Company:
                                </TableCell>
                                <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                    {job.company || 'Unknown'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: '#EECA74', borderBottom: 'none', fontWeight: 'bold' }}>
                                    Description:
                                </TableCell>
                                <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                    {job.description || 'No description available'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: '#EECA74', borderBottom: 'none', fontWeight: 'bold' }}>
                                    Location:
                                </TableCell>
                                <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                    {job.location || 'Not specified'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: '#EECA74', borderBottom: 'none', fontWeight: 'bold' }}>
                                    Tags:
                                </TableCell>
                                <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                    {job.tags ? job.tags.join(', ') : 'No tags'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: '#EECA74', borderBottom: 'none', fontWeight: 'bold' }}>
                                    Is Public:
                                </TableCell>
                                <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                    {job.is_public ? 'Yes' : 'No'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: '#EECA74', borderBottom: 'none', fontWeight: 'bold' }}>
                                    Status:
                                </TableCell>
                                <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                    {finalStatus || 'Not specified'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: '#EECA74', borderBottom: 'none', fontWeight: 'bold' }}>
                                    Date Posted:
                                </TableCell>
                                <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                    {job.date_posted ? new Date(job.date_posted).toLocaleString() : 'Unknown'}
                                </TableCell>
                            </TableRow>
                            {job.compensation && (
                                <TableRow>
                                    <TableCell sx={{ color: '#EECA74', borderBottom: 'none', fontWeight: 'bold' }}>
                                        Compensation:
                                    </TableCell>
                                    <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                        {renderCompensation()}
                                    </TableCell>
                                </TableRow>
                            )}
                            {job.message && (
                                <TableRow>
                                    <TableCell sx={{ color: '#EECA74', borderBottom: 'none', fontWeight: 'bold' }}>
                                        Message from Company:
                                    </TableCell>
                                    <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                        {job.message}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', mb: 2 }}>
                    {onBack && (
                        <Button variant="contained" onClick={onBack} sx={{ ...activeButtonSx, mr: 2 }}>
                            Back
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        onClick={handleMarkAsCompleted}
                        disabled={isButtonDisabled || loading || fetchingStatus}
                        sx={{
                            ...(isButtonDisabled ? disabledButtonSx : activeButtonSx),
                            '&.Mui-disabled': {
                                backgroundColor: '#9e9e9e',
                                color: '#fff',
                                border: '1px solid #757575',
                            },
                        }}
                    >
                        {loading ? 'Processing...' : buttonLabel}
                    </Button>
                </CardActions>
            </Card>
        </Box>
    );
};

export default AcceptedJobDetails;
