// components/ProtectedRoute.jsx
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext/index';

const ProtectedRoute = ({ children }) => {
    const { currentUser, loading } = useAuth();

    if (loading) return null; // Or show a loading spinner

    return currentUser ? children : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
