import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CancelPage = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ p: 3, textAlign: 'center', backgroundColor: '#121212', color: '#FFFFFF', minHeight: '100vh' }}>
            <Typography variant="h4" sx={{ color: '#EECA74', mb: 2 }}>
                Checkout Cancelled
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                It looks like you cancelled the checkout process. You can try again if you'd like.
            </Typography>
            <Button variant="contained" sx={{ backgroundColor: '#EECA74', color: '#000000' }} onClick={() => navigate('/')}>
                Go Home
            </Button>
        </Box>
    );
};

export default CancelPage;
