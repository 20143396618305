import React, { useState, useEffect } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { useAuth } from '../../contexts/authContext';
import Socials from './socials';
import {
    Typography,
    Avatar,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Paper,
    Select,
    MenuItem,
    Tooltip
} from '@mui/material';
import {useNavigate} from "react-router-dom";
import { supabase } from '../../supabase/supabaseClient';

const ProfilePage = () => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [editMode, setEditMode] = useState(false);
    const [profile, setProfile] = useState({
        userType: '',
        name: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        about: '',
        location: '',
        email: currentUser?.email || '',
        tags: [],
        area_of_focus: ''
    });
    const [newTag, setNewTag] = useState('');
    const [socialDialogOpen, setSocialDialogOpen] = useState(false);
    const [socials, setSocials] = useState([]);

    // NEW CODE: Define area_of_focus options
    const areaOfFocusOptions = [
        { title: 'Fashion', description: 'Showcasing clothing trends, styling tips, and wardrobe inspirations.' },
        { title: 'Beauty', description: 'Covering makeup tutorials, skincare routines, and product reviews.' },
        { title: 'Travel', description: 'Sharing destination guides, travel tips, and cultural experiences.' },
        { title: 'Food', description: 'Featuring recipes, restaurant reviews, and culinary adventures.' },
        { title: 'Fitness', description: 'Offering workout routines, health tips, and training advice.' },
        { title: 'Lifestyle', description: 'Highlighting daily living, personal growth, and home organization.' },
        { title: 'Technology', description: 'Reviewing gadgets, sharing tech news, and exploring innovations.' },
        { title: 'Gaming', description: 'Streaming gameplay, reviewing games, and discussing gaming culture.' },
        { title: 'Parenting', description: 'Providing family tips, parenting hacks, and kid-friendly content.' },
        { title: 'Home & Interior', description: 'Focusing on decor ideas, DIY projects, and interior design.' },
        { title: 'Photography', description: 'Offering tips, gear reviews, and showcasing photographic art.' },
        { title: 'Art & Design', description: 'Sharing creative work, design trends, and art tutorials.' },
        { title: 'Health & Wellness', description: 'Discussing mental health, nutrition, and holistic wellness.' },
        { title: 'Finance', description: 'Giving advice on saving, investing, and money management.' },
        { title: 'Music', description: 'Covering music reviews, instrument tutorials, and concert experiences.' },
        { title: 'DIY & Crafts', description: 'Showcasing handmade projects and creative crafting ideas.' },
        { title: 'Education', description: 'Providing learning resources, study tips, and educational content.' },
        { title: 'Comedy & Entertainment', description: 'Creating humorous sketches, parodies, and entertaining videos.' },
        { title: 'Sports', description: 'Analyzing games, training techniques, and athlete lifestyles.' },
        { title: 'Business & Entrepreneurship', description: 'Offering insights on startups, marketing, and career growth.' },
        { title: 'Outdoor & Adventure', description: 'Highlights camping, hiking, nature explorations, and adventurous outdoor activities.' },
        { title: 'Mindfulness & Meditation', description: 'Offers guidance on mindfulness practices, meditation techniques, and mental wellness strategies.' },
    ];

    // New state for delete account functionality
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState('');

    const handleOpenSocials = () => setSocialDialogOpen(true);

    // Fetch socials from your API and update state
    const fetchSocials = async () => {
        try {
            const response = await fetch('/api/get-socials', { credentials: 'include' });
            const data = await response.json();
            if (response.ok && data.data) {
                setSocials(data.data);
            } else {
                console.error('Error fetching socials');
            }
        } catch (error) {
            console.error('Error fetching socials:', error);
        }
    };

    // When Socials dialog closes, refresh the socials list
    const handleCloseSocials = () => {
        setSocialDialogOpen(false);
        fetchSocials();
    };

    // Function to remove a social account
    const handleRemoveSocial = async (socialId) => {
        try {
            const response = await fetch('/api/remove-social', {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ socialId }),
            });
            const data = await response.json();
            if (data.success) {
                // Remove the social from the local state
                setSocials((prevSocials) => prevSocials.filter((social) => social.id !== socialId));
            } else {
                console.error('Failed to remove social account');
            }
        } catch (error) {
            console.error('Error removing social account:', error);
        }
    };

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch('/api/getUserType', { credentials: 'include' });
                const data = await response.json();

                if (response.ok) {
                    setProfile({
                        ...data.userData,
                        userType: data.userType,
                        email: currentUser.email || '',
                        about: data.userData.about || '',
                        phone_number: data.userData.phone_number || '',
                        location: data.userType === 'company' ? data.userData.address : data.userData.location,
                        tags: data.userData.tags || [],
                        area_of_focus: data.userData.area_of_focus || '',
                        name:
                            data.userType === 'company'
                                ? data.userData.name || 'Unknown Company'
                                : `${data.userData.first_name || ''} ${data.userData.last_name || ''}`.trim(),
                        first_name: data.userData.first_name || '',
                        last_name: data.userData.last_name || '',
                    });
                } else {
                    console.error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchUserProfile();
        fetchSocials();
    }, [currentUser]);

    const handleChange = (prop) => (event) => {
        setProfile({ ...profile, [prop]: event.target.value });
    };

    const handlePlaceSelected = (place) => {
        if (!place.address_components) return;

        const city = place.address_components.find((component) =>
            component.types.includes('locality')
        )?.long_name;

        const state = place.address_components.find((component) =>
            component.types.includes('administrative_area_level_1')
        )?.short_name;

        if (city && state) {
            setProfile((prev) => ({ ...prev, location: `${city}, ${state}` }));
        } else {
            setProfile((prev) => ({ ...prev, location: place.formatted_address }));
        }
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleClose = () => {
        setEditMode(false);
    };

    const handleSave = async () => {
        try {
            const profileData = {
                userType: profile.userType,
                name: profile.userType === 'company' ? profile.name : undefined,
                first_name: profile.userType === 'influencer' ? profile.first_name : undefined,
                last_name: profile.userType === 'influencer' ? profile.last_name : undefined,
                phone_number: profile.phone_number,
                about: profile.about,
                location: profile.location,
            };
            // NEW CODE: Adding area_of_focus to profileData for influencers
            if (profile.userType !== 'company') {
                profileData.area_of_focus = profile.area_of_focus;
            }
            const endpoint =
                profile.userType === 'company' ? `/api/updateCompany` : `/api/updateInfluencer`;

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(profileData),
            });

            if (!response.ok) {
                throw new Error('Failed to update profile...');
            }

            if (profile.userType !== 'company') {
                const tagsResponse = await fetch(`/api/updateInfluencerTags`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify({ tags: profile.tags }),
                });

                const tagsData = await tagsResponse.json();

                if (!tagsResponse.ok || tagsData.success === false) {
                    throw new Error('Failed to update tags...');
                }
            }

            handleClose();
        } catch (error) {
            console.error('Failed to update profile:', error);
        }
    };

    const handleAddTag = () => {
        if (newTag && !profile.tags.includes(newTag)) {
            setProfile((prev) => ({ ...prev, tags: [...prev.tags, newTag] }));
            setNewTag('');
        }
    };

    const handleDeleteTag = (tagToDelete) => () => {
        setProfile((prev) => ({
            ...prev,
            tags: prev.tags.filter((tag) => tag !== tagToDelete),
        }));
    };
    // Delete Account Handlers (added without changing existing styling)
    const handleOpenDeleteDialog = () => setDeleteDialogOpen(true);
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setDeleteConfirmation('');
    };
    const handleDeleteAccount = async () => {
        if (deleteConfirmation.toLowerCase() !== 'delete') {
            alert("Please type 'delete' to confirm.");
            return;
        }
        try {
            const response = await fetch('/api/deleteAccount', {
                method: 'DELETE',
                credentials: 'include',
            });
            if (response.ok) {
                alert("Account deleted.");
                const { error } = await supabase.auth.signOut();
                if (error) console.error("Error signing out:", error);
                navigate('/login');

            } else {
                const data = await response.json();
                alert(`Error: ${data.message}`);
            }
        } catch (error) {
            console.error('Error deleting account:', error);
            alert('Error deleting account.');
        }
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <Paper
                elevation={4}
                sx={{
                    backgroundColor: 'background.default',
                    color: 'text.primary',
                    border: '4px solid',
                    borderColor: 'primary.main',
                    borderRadius: 3,
                    padding: 4,
                    maxWidth: 600,
                    width: '100%',
                }}
            >
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Avatar sx={{ width: 80, height: 80, bgcolor: 'primary.main', mx: 'auto' }}>
                        {profile.userType === 'company'
                            ? profile.name?.[0] || 'C'
                            : profile.first_name?.[0] || 'U'}
                    </Avatar>
                    <Typography variant="h5" sx={{ mt: 2, color: 'primary.main' }}>
                        {profile.userType === 'company'
                            ? profile.name
                            : `${profile.first_name} ${profile.last_name}`.trim()}
                    </Typography>
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Typography variant="body1">
                        <strong>Email:</strong> {profile.email}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Phone Number:</strong> {profile.phone_number}
                    </Typography>
                    <Typography variant="body1">
                        <strong>About:</strong> {profile.about}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Location:</strong> {profile.location}
                    </Typography>
                    {/* NEW CODE: Display area_of_focus for influencers */}
                    {profile.userType !== 'company' && profile.area_of_focus && (
                        <Typography variant="body1">
                            <strong>Area of Focus:</strong> {profile.area_of_focus}
                        </Typography>
                    )}
                    {profile.userType !== 'company' && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1">
                                <strong>Tags:</strong>
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                                {profile.tags.map((tag, index) => (
                                    <Chip
                                        key={index}
                                        label={tag}
                                        onDelete={handleDeleteTag(tag)}
                                        deleteIcon={<span style={{ color: '#000' }}>×</span>}
                                        sx={{
                                            backgroundColor: 'primary.main',
                                            color: '#000',
                                            fontWeight: 'bold',
                                        }}
                                    />
                                ))}
                            </Box>
                        </Box>
                    )}

                    {/* Display Social Accounts */}
                    {socials.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1">
                                <strong>Social Accounts:</strong>
                            </Typography>
                            {socials.map((social) => {
                                // Compute the link URL. For Instagram, remove the '@' from the URL.
                                let linkUrl = social.account_url;
                                if (social.platform.toLowerCase() === 'instagram') {
                                    linkUrl = social.account_url.replace('/@', '/');
                                }
                                return (
                                    <Box
                                        key={social.id}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            mt: 1,
                                            borderBottom: '1px solid',
                                            pb: 1,
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="body2" sx={{ mr: 1 }}>
                                                {social.platform}:
                                            </Typography>
                                            <a
                                                href={linkUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ color: 'inherit', textDecoration: 'underline' }}
                                            >
                                                {social.username}
                                            </a>
                                            <Button
                                                onClick={() => handleRemoveSocial(social.id)}
                                                variant="text"
                                                sx={{ color: 'red', ml: 'auto' }}
                                            >
                                                x
                                            </Button>
                                        </Box>
                                        <Typography variant="body2" sx={{ ml: 2 }}>
                                            Follower count: {social.follower_count}
                                        </Typography>
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={handleEdit}
                        onClose={() => setEditMode(false)}
                        variant="contained"
                        sx={{
                            backgroundColor: 'primary.main',
                            color: '#000',
                            borderRadius: 2,
                            fontWeight: 'bold',
                        }}
                    >
                        Edit Profile
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button
                        variant="contained"
                        onClick={handleOpenSocials}
                        sx={{ backgroundColor: 'primary.main', color: '#000' }}
                    >
                        Add Social Account
                    </Button>
                </Box>
                <Socials open={socialDialogOpen} onClose={handleCloseSocials} />
                {/* Delete Account Button */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleOpenDeleteDialog}
                        sx={{ fontWeight: 'bold' }}
                    >
                        Delete Account
                    </Button>
                </Box>
            </Paper>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                PaperProps={{ sx: { backgroundColor: 'black', color: 'white', border: '4px solid #EECA74' } }}
            >
                <DialogTitle sx={{ color: 'error.main' }}>Delete Account</DialogTitle>
                <DialogContent>
                    <Typography>
                        You are about to delete your account. Account can't be recovered after 30 days.
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                        Please type <strong>delete</strong> to confirm.
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Type 'delete' here"
                        fullWidth
                        variant="outlined"
                        value={deleteConfirmation}
                        onChange={(e) => setDeleteConfirmation(e.target.value)}
                        sx={{ mt: 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} sx={{ color: 'primary.main' }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteAccount}
                        sx={{ backgroundColor: 'error.main', color: '#000', fontWeight: 'bold' }}
                        disabled={deleteConfirmation.toLowerCase() !== 'delete'}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                key={editMode ? 'open' : 'closed'}
                open={editMode}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        backgroundColor: 'background.default',
                        color: 'text.primary',
                        border: '4px solid',
                        borderColor: 'primary.main',
                        borderRadius: 2,
                    },
                }}
            >
                <DialogTitle sx={{ color: 'primary.main' }}>Edit Your Profile</DialogTitle>
                <DialogContent>
                    {profile.userType === 'company' ? (
                        <TextField
                            margin="dense"
                            id="name"
                            label="Company Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={profile.name}
                            onChange={handleChange('name')}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: '#fff' },
                                    '&:hover fieldset': { borderColor: '#fff' },
                                    '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                                },
                            }}
                        />
                    ) : (
                        <>
                            <TextField
                                margin="dense"
                                id="first_name"
                                label="First Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={profile.first_name}
                                onChange={handleChange('first_name')}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': { borderColor: '#fff' },
                                        '&:hover fieldset': { borderColor: '#fff' },
                                        '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                                    },
                                }}
                            />
                            <TextField
                                margin="dense"
                                id="last_name"
                                label="Last Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={profile.last_name}
                                onChange={handleChange('last_name')}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': { borderColor: '#fff' },
                                        '&:hover fieldset': { borderColor: '#fff' },
                                        '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                                    },
                                }}
                            />
                        </>
                    )}
                    <TextField
                        margin="dense"
                        id="phone_number"
                        label="Phone Number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={profile.phone_number}
                        onChange={handleChange('phone_number')}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#fff' },
                                '&:hover fieldset': { borderColor: '#fff' },
                                '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                            },
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="about"
                        label="About Me"
                        type="text"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        value={profile.about}
                        onChange={handleChange('about')}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#fff' },
                                '&:hover fieldset': { borderColor: '#fff' },
                                '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                            },
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="location"
                        label="Location"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={profile.location}
                        onChange={handleChange('location')}
                        InputProps={{
                            inputComponent: Autocomplete,
                            inputProps: {
                                apiKey: "AIzaSyAcSt2xik5cqZsFHltHmj2DWxdqSXFybT0", // Replace with your API key
                                options: {
                                    types: ['(cities)'],
                                    componentRestrictions: { country: 'us' },
                                },
                                onPlaceSelected: handlePlaceSelected,
                                style: {
                                    fontSize: '16px',
                                    color: '#FFFFFF',
                                },
                            },
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#fff' },
                                '&:hover fieldset': { borderColor: '#fff' },
                                '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                            },
                        }}
                    />
                    {profile.userType !== 'company' && (
                        <>
                            {/* NEW CODE: Added Area of Focus dropdown */}
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Area of Focus:</strong>
                                </Typography>
                                <Select
                                    value={profile.area_of_focus}
                                    onChange={handleChange('area_of_focus')}
                                    displayEmpty
                                    fullWidth
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: 'background.default', // Uses theme background (#000000)
                                                color: 'text.primary', // Ensures text is white
                                                border: '2px solid',
                                                borderColor: 'primary.main'
                                            }
                                        }
                                    }}
                                >
                                {areaOfFocusOptions.map((option) => (
                                        <MenuItem key={option.title} value={option.title}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                <Typography>{option.title}</Typography>
                                                <Tooltip title={option.description} arrow>
                                                    {/* Question mark styled as a grey circle (no border) */}
                                                    <Typography sx={{
                                                        ml: 1,
                                                        cursor: 'pointer',
                                                        width: 20,
                                                        height: 20,
                                                        borderRadius: '50%',
                                                        backgroundColor: '#808080',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        fontSize: '12px'
                                                    }}>
                                                        ?
                                                    </Typography>
                                                </Tooltip>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Tags:</strong>
                                </Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                                    {profile.tags.map((tag, index) => (
                                        <Chip
                                            key={index}
                                            label={tag}
                                            onDelete={handleDeleteTag(tag)}
                                            deleteIcon={<span style={{ color: '#000' }}>×</span>}
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: '#000',
                                                fontWeight: 'bold',
                                            }}
                                        />
                                    ))}
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <TextField
                                        id="new-tag"
                                        label="Add a Tag"
                                        type="text"
                                        variant="outlined"
                                        value={newTag}
                                        onChange={(e) => setNewTag(e.target.value)}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': { borderColor: '#fff' },
                                                '&:hover fieldset': { borderColor: '#fff' },
                                                '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                                            },
                                        }}
                                    />
                                    <Button
                                        onClick={handleAddTag}
                                        variant="contained"
                                        sx={{ backgroundColor: 'primary.main', color: '#000' }}
                                    >
                                        Add
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{ color: 'primary.main' }}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} sx={{ backgroundColor: 'primary.main', color: '#000', fontWeight: 'bold' }}>
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ProfilePage;
