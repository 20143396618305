import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function createData(jobTitle, companyName, complete_by, status) {
  // This function creates a row object for the table
  return { jobTitle, companyName, complete_by, status };
}

const fetchData = async () => {
    try {
        const response = await fetch(`/api/jobOffers`, { credentials: 'include' });

        if (!response.ok) {
            console.error(`Error fetching job offers: ${response.status} ${response.statusText}`);
            return [];
        }

        const data = await response.json();
        return data.map((job) =>
            createData(
                job.title,
                job.company,
                job.complete_by,
                job.status
            )
        );
    } catch (error) {
        console.error('Network or parsing error:', error);
        return [];
    }
};


export default function BrandDealTableInfluencer() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchData().then(setRows); // Fetch data on component mount and set it to rows
  }, []); // Empty dependency array to run only once on mount

  return (
      <Box
          sx={{
            margin: 2,
            padding: 2,
            backgroundColor: '#000000', // Black background
            borderRadius: '16px',
            border: '2px solid #EECA74', // Gold border
            color: '#EECA74', // Gold text color
            boxShadow: 3,
          }}
      >
        <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 2 }}
        >
          Influencer Campaigns/Jobs
        </Typography>
        <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Table aria-label="influencer campaign table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#EECA74', fontWeight: 'bold' }}>Job Title and Description</TableCell>
                <TableCell sx={{ color: '#EECA74', fontWeight: 'bold' }}>Company</TableCell>
                <TableCell sx={{ color: '#EECA74', fontWeight: 'bold' }}>Complete By</TableCell>
                <TableCell sx={{ color: '#EECA74', fontWeight: 'bold' }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                  rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ color: '#EECA74' }}>{row.jobTitle}</TableCell>
                        <TableCell sx={{ color: '#EECA74' }}>{row.companyName}</TableCell>
                        <TableCell sx={{ color: '#EECA74' }}>{row.complete_by}</TableCell>
                        <TableCell sx={{ color: '#EECA74' }}>{row.status}</TableCell>
                      </TableRow>
                  ))
              ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center" sx={{ color: '#EECA74' }}>
                      No accepted job offers found
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
  );
}
