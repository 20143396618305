import React, { useState, useEffect } from 'react';
import {
    Checkbox,
    FormControlLabel,
    TextField,
    Button,
    Typography,
    MenuItem,
    Select
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import { useTheme } from '@mui/material/styles';
import Autocomplete from 'react-google-autocomplete';

const JobPostingForm = ({ onJobPost }) => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [isPublic, setIsPublic] = useState(false);
    const [completeBy, setCompleteBy] = useState('');
    const [numberOfPosts, setNumberOfPosts] = useState('');
    // Updated state: separate fields for each compensation type
    const [compensationType, setCompensationType] = useState('');
    const [dollarCompensation, setDollarCompensation] = useState('');
    const [otherCompensation, setOtherCompensation] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Handle Google Places Autocomplete
    const handlePlaceSelected = (place) => {
        if (!place.address_components) return;
        const city = place.address_components.find(component =>
            component.types.includes('locality')
        )?.long_name;
        const state = place.address_components.find(component =>
            component.types.includes('administrative_area_level_1')
        )?.short_name;
        if (city && state) {
            setLocation(`${city}, ${state}`);
        } else {
            setLocation(place.formatted_address);
        }
    };

    const handleAddTag = () => {
        if (newTag && !tags.includes(newTag)) {
            setTags([...tags, newTag]);
            setNewTag('');
        }
    };

    const handleDeleteTag = (tagToDelete) => {
        setTags(tags.filter(tag => tag !== tagToDelete));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Build the compensation object based on the selected type
            let compensation = {};
            if (compensationType === 'dollar') {
                compensation = { type: 'dollar', dollar: dollarCompensation };
            } else if (compensationType === 'other') {
                compensation = { type: 'other', other: otherCompensation };
            } else if (compensationType === 'both') {
                compensation = { type: 'both', dollar: dollarCompensation, other: otherCompensation };
            }

            const formData = {
                title,
                description,
                location,
                tags,
                isPublic,
                complete_by: completeBy,
                number_of_posts: numberOfPosts,
                compensation,
            };

            const response = await fetch('/api/postJob', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
                credentials: 'include',
            });

            if (response.ok) {
                const newJob = await response.json();
                onJobPost(newJob);
                // Clear the form fields
                setTitle('');
                setDescription('');
                setLocation('');
                setTags([]);
                setIsPublic(false);
                setCompleteBy('');
                setNumberOfPosts('');
                setCompensationType('');
                setDollarCompensation('');
                setOtherCompensation('');
            } else {
                console.error('Error posting the job:', response.statusText);
            }
        } catch (error) {
            setErrorMessage(`Error posting the job: ${error.message}`);
        }
    };

    // Styling for input fields (matches theme)
    const textFieldStyles = {
        '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: theme.palette.text.primary },
            '&:hover fieldset': { borderColor: theme.palette.text.primary },
            '&.Mui-focused fieldset': { borderColor: theme.palette.primary.main },
        },
        '& .MuiInputLabel-root': { color: theme.palette.text.primary },
        '& .MuiInputLabel-root.Mui-focused': { color: theme.palette.primary.main },
    };

    // Styling for dropdown menus
    const menuStyles = {
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            border: `2px solid ${theme.palette.text.primary}`,
            borderRadius: '4px',
        },
        '& .MuiMenuItem-root': {
            '&:hover': { backgroundColor: theme.palette.primary.main },
        },
    };

    return (
        <form onSubmit={handleSubmit}>
            <Typography variant="h5" sx={{ mb: 2 }}>
                Post a Job
            </Typography>
            <TextField
                label="Job Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                fullWidth
                margin="normal"
                sx={textFieldStyles}
            />
            <TextField
                label="Job Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                fullWidth
                multiline
                rows={4}
                margin="normal"
                sx={textFieldStyles}
            />

            {/* Location Autocomplete */}
            <TextField
                label="Location"
                fullWidth
                margin="normal"
                sx={textFieldStyles}
                InputProps={{
                    inputComponent: Autocomplete,
                    inputProps: {
                        apiKey: "AIzaSyAcSt2xik5cqZsFHltHmj2DWxdqSXFybT0",
                        options: { types: ['(cities)'], componentRestrictions: { country: 'us' } },
                        defaultValue: location,
                        onPlaceSelected: handlePlaceSelected,
                        style: { color: theme.palette.text.primary, fontSize: '16px' },
                    }
                }}
            />

            {/* Tags Field */}
            <div>
                <TextField
                    label="Add a Tag"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    placeholder="Enter a tag"
                    size="small"
                    margin="normal"
                    sx={textFieldStyles}
                />
                <Button onClick={handleAddTag} variant="contained" size="small">
                    Add
                </Button>
                <div>
                    {tags.map((tag, index) => (
                        <span key={index} style={{ marginRight: '5px' }}>
              {tag}{' '}
                            <Button
                                size="small"
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleDeleteTag(tag)}
                            >
                x
              </Button>
            </span>
                    ))}
                </div>
            </div>

            <TextField
                label="Complete By"
                type="date"
                value={completeBy}
                onChange={(e) => setCompleteBy(e.target.value)}
                fullWidth
                margin="normal"
                sx={textFieldStyles}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                label="Number of Posts"
                type="number"
                value={numberOfPosts}
                onChange={(e) => setNumberOfPosts(e.target.value)}
                fullWidth
                margin="normal"
                sx={textFieldStyles}
            />

            <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
                Compensation:
            </Typography>
            <Select
                value={compensationType}
                onChange={(e) => setCompensationType(e.target.value)}
                fullWidth
                displayEmpty
                sx={{ ...textFieldStyles, ...menuStyles }}
                MenuProps={{ sx: menuStyles }}
            >
                <MenuItem value="" disabled>Select Compensation Type</MenuItem>
                <MenuItem value="dollar">Dollar Amount</MenuItem>
                <MenuItem value="other">Other Compensation</MenuItem>
                <MenuItem value="both">Both</MenuItem>
            </Select>

            {/* Conditionally render compensation fields based on selection */}
            {compensationType === 'dollar' && (
                <TextField
                    label="Dollar Amount"
                    value={dollarCompensation}
                    onChange={(e) => setDollarCompensation(e.target.value)}
                    fullWidth
                    margin="normal"
                    sx={textFieldStyles}
                    required
                />
            )}
            {compensationType === 'other' && (
                <TextField
                    label="Other Compensation Details"
                    value={otherCompensation}
                    onChange={(e) => setOtherCompensation(e.target.value)}
                    fullWidth
                    margin="normal"
                    sx={textFieldStyles}
                    required
                />
            )}
            {compensationType === 'both' && (
                <>
                    <TextField
                        label="Dollar Amount"
                        value={dollarCompensation}
                        onChange={(e) => setDollarCompensation(e.target.value)}
                        fullWidth
                        margin="normal"
                        sx={textFieldStyles}
                        required
                    />
                    <TextField
                        label="Other Compensation Details"
                        value={otherCompensation}
                        onChange={(e) => setOtherCompensation(e.target.value)}
                        fullWidth
                        margin="normal"
                        sx={textFieldStyles}
                        required
                    />
                </>
            )}

            <FormControlLabel
                control={<Checkbox checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)} />}
                label="Make job public (Visible to influencers)"
            />
            {errorMessage && <Typography color="error">{errorMessage}</Typography>}
            <Button type="submit" variant="contained" color="primary">
                Post Job
            </Button>
        </form>
    );
};

export default JobPostingForm;
