import React from 'react';
import {
    Box,
    Typography,
    Button,
    Chip,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid
} from '@mui/material';

const ApplyToJob = ({ job, onBack }) => {
    const handleApply = async () => {
        console.log('location in apply to job: ', job.location)
        try {
            const response = await fetch('/api/applyToJob', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ jobId: job.id }),
                credentials: 'include',
            });
            const data = await response.json();
            if (data.status === 'success') {
                alert('Successfully applied for the job!');
            } else {
                alert('Failed to apply for the job.');
            }
        } catch (error) {
            console.error('Error applying for the job:', error);
        }
    };

    // Helper function to format dates
    const formatDate = (dateStr) => {
        if (!dateStr) return 'N/A';
        const date = new Date(dateStr);
        return date.toLocaleDateString();
    };

    // Render the compensation details based on the new fields
    const renderCompensation = () => {
        let details = [];
        if (job.dollar_compensation) {
            details.push(`Dollar amount: $${job.dollar_compensation}`);
        }
        if (job.other_compensation) {
            details.push(`Other Compensation: ${job.other_compensation}`);
        }
        return details.length > 0 ? details.join(' | ') : 'Not specified';
    };

    return (
        <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
            <Button onClick={onBack} sx={{ color: '#EECA74', mb: 2 }}>
                ← Back to Job Board
            </Button>
            <Card sx={{ backgroundColor: 'black', color: 'white', borderRadius: 2, boxShadow: 3 }}>
                <CardHeader
                    title={
                        <Typography variant="h4" sx={{ color: '#EECA74' }}>
                            {job.title}
                        </Typography>
                    }
                />
                <Divider sx={{ borderColor: '#EECA74' }} />
                <CardContent>
                    {/* New Description Section */}
                    <Typography variant="subtitle1" sx={{ color: '#EECA74', mb: 1 }}>
                        Description:
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#FFFFFF', mb: 2 }}>
                        {job.description}
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1">
                                <strong style={{ color: '#EECA74' }}>Location:</strong>{' '}
                                <span style={{ color: '#FFFFFF' }}>{job.location || 'N/A'}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1">
                                <strong style={{ color: '#EECA74' }}>Date Posted:</strong>{' '}
                                <span style={{ color: '#FFFFFF' }}>{formatDate(job.date_posted)}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1">
                                <strong style={{ color: '#EECA74' }}>Complete By:</strong>{' '}
                                <span style={{ color: '#FFFFFF' }}>{formatDate(job.complete_by)}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1">
                                <strong style={{ color: '#EECA74' }}>Number of Posts:</strong>{' '}
                                <span style={{ color: '#FFFFFF' }}>{job.number_of_posts || 'N/A'}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <strong style={{ color: '#EECA74' }}>Compensation:</strong>{' '}
                                <span style={{ color: '#FFFFFF' }}>{renderCompensation()}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" sx={{ color: '#EECA74' }}>
                                Tags:
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                                {job.tags && job.tags.length > 0 ? (
                                    job.tags.map((tag, idx) => (
                                        <Chip
                                            key={idx}
                                            label={tag}
                                            sx={{ mr: 1, mb: 1, backgroundColor: '#EECA74', color: '#000000' }}
                                        />
                                    ))
                                ) : (
                                    <Typography variant="body2" sx={{ color: '#FFFFFF' }}>No tags</Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider sx={{ borderColor: '#EECA74' }} />
                <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        onClick={handleApply}
                        sx={{ backgroundColor: '#EECA74', color: '#000000', px: 4 }}
                    >
                        Apply for this Job
                    </Button>
                </Box>
            </Card>
        </Box>
    );
};

export default ApplyToJob;
