import React, { useState, useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox, Button, Typography, MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Autocomplete from 'react-google-autocomplete';

function EditJobForm({ selectedJob, onSave, onDelete }) {
    const theme = useTheme();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [isPublic, setIsPublic] = useState(false);
    const [completeBy, setCompleteBy] = useState('');
    const [numberOfPosts, setNumberOfPosts] = useState('');
    const [compensationType, setCompensationType] = useState('');
    const [dollarCompensation, setDollarCompensation] = useState('');
    const [otherCompensation, setOtherCompensation] = useState('');

    // Load job data when selectedJob changes
    useEffect(() => {
        if (selectedJob) {
            setTitle(selectedJob.title || '');
            setDescription(selectedJob.description || '');
            setLocation(selectedJob.location || '');
            setTags(selectedJob.tags || []);
            setIsPublic(selectedJob.is_public || false);
            setCompleteBy(selectedJob.complete_by || '');
            setNumberOfPosts(selectedJob.number_of_posts || '');

            // Set compensation fields from the new columns
            setDollarCompensation(selectedJob.dollar_compensation || '');
            setOtherCompensation(selectedJob.other_compensation || '');

            // Determine compensation type based on values present
            if (selectedJob.dollar_compensation && selectedJob.other_compensation) {
                setCompensationType('both');
            } else if (selectedJob.dollar_compensation) {
                setCompensationType('dollar');
            } else if (selectedJob.other_compensation) {
                setCompensationType('other');
            } else {
                setCompensationType('');
            }
        }
    }, [selectedJob]);

    // Handle Google Places Autocomplete
    const handlePlaceSelected = (place) => {
        if (!place.address_components) return;
        const city = place.address_components.find(component =>
            component.types.includes('locality')
        )?.long_name;
        const state = place.address_components.find(component =>
            component.types.includes('administrative_area_level_1')
        )?.short_name;
        if (city && state) {
            setLocation(`${city}, ${state}`);
        } else {
            setLocation(place.formatted_address);
        }
    };

    const handleAddTag = () => {
        if (newTag && !tags.includes(newTag)) {
            setTags([...tags, newTag]);
            setNewTag('');
        }
    };

    const handleDeleteTag = (tagToDelete) => {
        setTags(tags.filter(tag => tag !== tagToDelete));
    };

    const handleSave = async () => {
        // Build the compensation object based on the compensation type
        let compensation = {};
        if (compensationType === 'dollar') {
            compensation = { type: 'dollar', dollar: dollarCompensation };
        } else if (compensationType === 'other') {
            compensation = { type: 'other', other: otherCompensation };
        } else if (compensationType === 'both') {
            compensation = { type: 'both', dollar: dollarCompensation, other: otherCompensation };
        }

        const updatedJob = {
            title,
            description,
            location,
            tags,
            is_public: isPublic,
            complete_by: completeBy,
            number_of_posts: numberOfPosts,
            // The API should handle the compensation object and update the separate columns accordingly.
            compensation,
        };

        const response = await fetch(`/api/jobs-updates/${selectedJob.id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updatedJob),
        });

        if (response.ok) {
            alert('Job updated successfully');
            onSave({ ...selectedJob, ...updatedJob });
        } else {
            const errorData = await response.json();
            alert(`Failed to update job: ${errorData.message}`);
        }
    };

    const textFieldStyles = {
        '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: theme.palette.text.primary },
            '&:hover fieldset': { borderColor: theme.palette.text.primary },
            '&.Mui-focused fieldset': { borderColor: theme.palette.primary.main },
        },
        '& .MuiInputLabel-root': { color: theme.palette.text.primary },
        '& .MuiInputLabel-root.Mui-focused': { color: theme.palette.primary.main },
    };

    const menuStyles = {
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            border: `2px solid ${theme.palette.text.primary}`,
            borderRadius: '4px',
        },
        '& .MuiMenuItem-root': {
            '&:hover': { backgroundColor: theme.palette.primary.main },
        },
    };

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Typography variant="h5" sx={{ mb: 2 }}>
                Edit Job: {selectedJob.title}
            </Typography>
            <TextField
                label="Job Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                fullWidth
                margin="normal"
                sx={textFieldStyles}
            />
            <TextField
                label="Job Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                fullWidth
                multiline
                rows={4}
                margin="normal"
                sx={textFieldStyles}
            />
            <TextField
                label="Location"
                fullWidth
                margin="normal"
                sx={textFieldStyles}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                InputProps={{
                    inputComponent: Autocomplete,
                    inputProps: {
                        apiKey: "AIzaSyAcSt2xik5cqZsFHltHmj2DWxdqSXFybT0",
                        options: { types: ['(cities)'], componentRestrictions: { country: 'us' } },
                        onPlaceSelected: handlePlaceSelected,
                        style: { color: theme.palette.text.primary, fontSize: '16px' },
                    },
                }}
            />
            <div>
                <TextField
                    label="Add a Tag"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    placeholder="Enter a tag"
                    size="small"
                    margin="normal"
                    sx={textFieldStyles}
                />
                <Button onClick={handleAddTag} variant="contained" size="small">
                    Add
                </Button>
                <div>
                    {tags.map((tag, index) => (
                        <span key={index} style={{ marginRight: '5px' }}>
              {tag}{' '}
                            <Button
                                size="small"
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleDeleteTag(tag)}
                            >
                x
              </Button>
            </span>
                    ))}
                </div>
            </div>
            <TextField
                label="Complete By"
                type="date"
                value={completeBy}
                onChange={(e) => setCompleteBy(e.target.value)}
                fullWidth
                margin="normal"
                sx={textFieldStyles}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                label="Number of Posts"
                type="number"
                value={numberOfPosts}
                onChange={(e) => setNumberOfPosts(e.target.value)}
                fullWidth
                margin="normal"
                sx={textFieldStyles}
            />
            <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
                Compensation:
            </Typography>
            <Select
                value={compensationType}
                onChange={(e) => setCompensationType(e.target.value)}
                fullWidth
                displayEmpty
                sx={{ ...textFieldStyles, ...menuStyles }}
                MenuProps={{ sx: menuStyles }}
            >
                <MenuItem value="" disabled>Select Compensation Type</MenuItem>
                <MenuItem value="dollar">Dollar Amount</MenuItem>
                <MenuItem value="other">Other Compensation</MenuItem>
                <MenuItem value="both">Both</MenuItem>
            </Select>
            {compensationType === 'dollar' && (
                <TextField
                    label="Dollar Amount"
                    value={dollarCompensation}
                    onChange={(e) => setDollarCompensation(e.target.value)}
                    fullWidth
                    margin="normal"
                    sx={textFieldStyles}
                    required
                />
            )}
            {compensationType === 'other' && (
                <TextField
                    label="Other Compensation Details"
                    value={otherCompensation}
                    onChange={(e) => setOtherCompensation(e.target.value)}
                    fullWidth
                    margin="normal"
                    sx={textFieldStyles}
                    required
                />
            )}
            {compensationType === 'both' && (
                <>
                    <TextField
                        label="Dollar Amount"
                        value={dollarCompensation}
                        onChange={(e) => setDollarCompensation(e.target.value)}
                        fullWidth
                        margin="normal"
                        sx={textFieldStyles}
                        required
                    />
                    <TextField
                        label="Other Compensation Details"
                        value={otherCompensation}
                        onChange={(e) => setOtherCompensation(e.target.value)}
                        fullWidth
                        margin="normal"
                        sx={textFieldStyles}
                        required
                    />
                </>
            )}
            <FormControlLabel
                control={<Checkbox checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)} />}
                label="Make job public (Visible to influencers)"
            />
            <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{ mt: 2 }}
            >
                Save Changes
            </Button>
            <Button
                type="button"
                variant="outlined"
                color="error"
                onClick={() => {
                    if (window.confirm("Are you sure you want to delete this job?")) {
                        onDelete(); // call the parent handler
                    }
                }}
                sx={{ mt: 2, ml: 2 }}
            >
                Delete Job
            </Button>

        </form>
    );
}

export default EditJobForm;
