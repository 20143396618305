import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
        backgroundColor: '#EECA74', // Gold
        borderRadius: '0px',
        opacity: 0.8,
        color: '#000000',           // Black text
        border: 'none',
        display: 'block'
    };
    return { style };
};

const MyCalendar = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        fetch('/api/getUserType')
            .then(res => res.json())
            .then(userData => {
                //console.log('User data from getUserType:', userData);
                if (userData.userType && userData.userData) {
                    const userType = userData.userType;
                    const userId = userData.userData.id;
                   // console.log("User type in calendar:", userType);
                    //console.log("User id in calendar:", userId);
                    return fetch(`/api/calendar-events?userType=${userType}&userId=${userId}`);
                } else {
                    throw new Error('User type not found');
                }
            })
            .then(res => {
                console.log('Response status for calendar-events:', res.status);
                return res.json();
            })
            .then(data => {
                //console.log("Calendar events fetched:", data);
                setEvents(data);
            })
            .catch(err => console.error('Error fetching calendar events:', err));
    }, []);

    return (
        <div style={{ height: 500 }}>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                defaultView="month"
                style={{ height: '100%' }}
                eventPropGetter={eventStyleGetter}
            />
        </div>
    );
};

export default MyCalendar;
