import React, { useState, useEffect } from 'react';
import CommonFrame from './CommonFrame';
import {
    Button,
    Tabs,
    Tab,
    Typography,
    Container,
    Card,
    CardActions,
    CardContent,
    Box,
    Table,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material';
import AcceptedJobDetails from './jobOffers/acceptedJobDetails';

const JobOffersPage = () => {
    const [jobOffers, setJobOffers] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobAccepted, setJobAccepted] = useState(false);
    const [checkingAcceptance, setCheckingAcceptance] = useState(false);
    const [acceptedJobDetailsView, setAcceptedJobDetailsView] = useState(false);
    const [selectedTab, setSelectedTab] = useState('active'); // "active" or "completed"

    useEffect(() => {
        fetchJobOffers();
    }, []);

    const fetchJobOffers = async () => {
        try {
            const response = await fetch(`/api/jobOffers`, { credentials: 'include' });
            if (response.ok) {
                let data = await response.json();
                // For each job, call the completion status API and update its status field.
                data = await Promise.all(
                    data.map(async (job) => {
                        try {
                            const compResponse = await fetch(
                                `/api/getCompletionStatus?jobId=${job.job_id}`,
                                { credentials: 'include' }
                            );
                            if (compResponse.ok) {
                                const compData = await compResponse.json();
                                // Update the job's status from the completion status data.
                                return { ...job, status: compData.data.status };
                            } else {
                                return job;
                            }
                        } catch (error) {
                            console.error(`Error fetching completion status for job ${job.job_id}:`, error);
                            return job;
                        }
                    })
                );
                setJobOffers(data);
            } else {
                throw new Error('Failed to fetch job offers');
            }
        } catch (error) {
            console.error('Error fetching job offers:', error);
        }
    };

    // Filter jobs based on the selected tab.
    const filteredJobOffers = jobOffers.filter((job) =>
        selectedTab === 'active' ? job.status !== 'completed' : job.status === 'completed'
    );

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setSelectedJob(null); // clear selection when switching tabs
    };

    const handleJobSelection = (jobObject) => {
        setSelectedJob(jobObject);
        setJobAccepted(false);
        setAcceptedJobDetailsView(false); // reset accepted view when selecting a new job
        setCheckingAcceptance(true);
        checkIfJobAccepted(jobObject.job_id);
    };

    const checkIfJobAccepted = async (jobId) => {
        try {
            const response = await fetch(`/api/checkJobAcceptance`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ jobId }),
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setJobAccepted(data.isAccepted || false);
            }
        } catch (error) {
            console.error('Error checking job acceptance status:', error);
        } finally {
            setCheckingAcceptance(false);
        }
    };

    const handleAcceptJob = async () => {
        if (!selectedJob) {
            alert('Please select a job to accept.');
            return;
        }
        try {
            const response = await fetch('/api/acceptJob', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ jobId: selectedJob.job_id }),
                credentials: 'include'
            });
            const data = await response.json();
            if (data.message === 'Job accepted successfully') {
                alert(`Accepted job: ${selectedJob.title}`);
                setJobAccepted(true);
            } else {
                alert('Failed to accept job.');
            }
        } catch (error) {
            console.error('Error accepting job:', error);
        }
    };

    const handleRejectJob = async () => {
        if (!selectedJob) {
            alert('Please select a job to reject.');
            return;
        }
        try {
            const response = await fetch('/api/rejectJobOffer', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ jobId: selectedJob.job_id }),
                credentials: 'include'
            });
            const data = await response.json();
            if (data.message === 'Job offer rejected successfully') {
                alert(`Rejected job: ${selectedJob.title}`);
                fetchJobOffers();
                setSelectedJob(null);
            } else {
                alert('Failed to reject job.');
            }
        } catch (error) {
            console.error('Error rejecting job offer:', error);
        }
    };

    const renderCompensation = () => {
        const dollar = selectedJob.dollar_compensation;
        const other = selectedJob.other_compensation;

        if (!dollar && !other) return 'Not specified';

        if (dollar && other) {
            return `Dollar Compensation: $${dollar} + Other Compensation: ${other}`;
        } else if (dollar) {
            return `Dollar Compensation: $${dollar}`;
        } else if (other) {
            return `Other Compensation: ${other}`;
        }
    };


    const jobDetailsUI = (
        <Container maxWidth="sm" sx={{ marginTop: 2 }}>
            {selectedJob && (
                <>
                    <Typography variant="h6" textAlign="center">
                        Selected Job: {selectedJob.title || 'Untitled Job'}
                    </Typography>
                    <Card variant="outlined" sx={{ marginTop: 2, backgroundColor: 'black' }}>
                        <CardContent>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ color: '#EECA74', fontWeight: 'bold', borderBottom: 'none' }}>
                                            Company:
                                        </TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                            {selectedJob.company || 'Unknown'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: '#EECA74', fontWeight: 'bold', borderBottom: 'none' }}>
                                            Description:
                                        </TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                            {selectedJob.description || 'No description available'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: '#EECA74', fontWeight: 'bold', borderBottom: 'none' }}>
                                            Location:
                                        </TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                            {selectedJob.location || 'Not specified'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: '#EECA74', fontWeight: 'bold', borderBottom: 'none' }}>
                                            Tags:
                                        </TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                            {selectedJob.tags ? selectedJob.tags.join(', ') : 'No tags'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: '#EECA74', fontWeight: 'bold', borderBottom: 'none' }}>
                                            Is Public:
                                        </TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                            {selectedJob.is_public ? 'Yes' : 'No'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: '#EECA74', fontWeight: 'bold', borderBottom: 'none' }}>
                                            Status:
                                        </TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                            {selectedJob.status || 'Not specified'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: '#EECA74', fontWeight: 'bold', borderBottom: 'none' }}>
                                            Date Posted:
                                        </TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                            {selectedJob.date_posted ? new Date(selectedJob.date_posted).toLocaleString() : 'Unknown'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: '#EECA74', fontWeight: 'bold', borderBottom: 'none' }}>
                                            Compensation:
                                        </TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                            {renderCompensation()}
                                        </TableCell>
                                    </TableRow>
                                    {selectedJob.message && (
                                        <TableRow>
                                            <TableCell sx={{ color: '#EECA74', fontWeight: 'bold', borderBottom: 'none' }}>
                                                Message from Company:
                                            </TableCell>
                                            <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                                {selectedJob.message}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'center', marginBottom: 2 }}>
                            <Button variant="contained" color="primary" onClick={handleAcceptJob} sx={{ marginRight: '10px' }}>
                                Accept Job!
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleRejectJob}>
                                Reject Job
                            </Button>
                        </CardActions>
                    </Card>
                </>
            )}
            {!selectedJob && <Typography textAlign="center">Please select a job to see details.</Typography>}
        </Container>
    );

    const jobActionUI = checkingAcceptance ? (
        <Typography variant="h6" textAlign="center" marginTop={2}>
            Checking job acceptance status...
        </Typography>
    ) : jobAccepted ? (
        acceptedJobDetailsView ? (
            // Pass fetchJobOffers as onJobCompleted so that changes update the parent view immediately.
            <AcceptedJobDetails job={selectedJob} onJobCompleted={fetchJobOffers} />
        ) : (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="h5">You have accepted this job</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() => setAcceptedJobDetailsView(true)}
                >
                    View Job Details
                </Button>
            </Box>
        )
    ) : (
        jobDetailsUI
    );

    return (
        <Container maxWidth={false}>
            {/* Tabs above the job list */}
            <Box sx={{ mb: 2 }}>
                <Tabs value={selectedTab} onChange={handleTabChange} centered>
                    <Tab label="Active" value="active" />
                    <Tab label="Completed" value="completed" />
                </Tabs>
            </Box>
            <CommonFrame items={filteredJobOffers} onSelectItem={handleJobSelection}>
                {jobActionUI}
            </CommonFrame>
        </Container>
    );
};

export default JobOffersPage;
