import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { useAuth } from '../../contexts/authContext';

const Billing = () => {
    const { currentUser } = useAuth();
    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(false);

    // Fetch subscription info from your backend
    useEffect(() => {
        const fetchSubscription = async () => {
            setLoading(true);
            try {
                const response = await fetch('/api/get-subscription', {
                    method: 'GET',
                    credentials: 'include',
                });
                const data = await response.json();
                setSubscription(data.subscription);
            } catch (error) {
                console.error('Error fetching subscription:', error);
            }
            setLoading(false);
        };

        if (currentUser && currentUser.stripe_customer_id) {
            fetchSubscription();
        }
    }, [currentUser]);

    // Handle subscription plan purchase via checkout session
    const handleSubscribe = async (priceId) => {
        try {
            const response = await fetch('/api/create-checkout-session', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ priceId }),
            });
            const data = await response.json();
            if (data.url) {
                window.location.href = data.url;
            }
        } catch (error) {
            console.error('Error initiating checkout session:', error);
        }
    };

    // Handle manage billing via billing portal
    const handleManageBilling = async () => {
        try {
            const res = await fetch('/api/create-billing-portal-session', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });
            const data = await res.json();
            if (data.url) {
                window.location.href = data.url;
            }
        } catch (error) {
            console.error('Error creating billing portal session:', error);
        }
    };

    return (
        <Box
            sx={{
                p: 3,
                backgroundColor: '#121212',
                color: '#FFFFFF',
                minHeight: '100vh',
                textAlign: 'center',
            }}
        >
            <Typography variant="h4" gutterBottom sx={{ color: '#EECA74' }}>
                Billing Information
            </Typography>
            {loading ? (
                <CircularProgress color="inherit" />
            ) : subscription ? (
                <Box>
                    <Typography variant="body1">
                        Subscription ID: {subscription.stripe_subscription_id}
                    </Typography>
                    <Typography variant="body1">
                        Status: {subscription.subscription_status}
                    </Typography>
                    <Typography variant="body1">
                        Start Date: {new Date(subscription.subscription_start).toLocaleString()}
                    </Typography>
                    <Typography variant="body1">
                        End Date: {new Date(subscription.subscription_end).toLocaleString()}
                    </Typography>
                    <Typography variant="body1">
                        Active: {subscription.active ? 'Yes' : 'No'}
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{ mt: 2, backgroundColor: '#EECA74', color: '#000000' }}
                        onClick={handleManageBilling}
                    >
                        Manage Billing
                    </Button>
                </Box>
            ) : (
                <Box>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        No active subscription found. Choose a plan:
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{ m: 1, backgroundColor: '#EECA74', color: '#000000' }}
                        onClick={() => handleSubscribe('price_1QxDBHAtLQhVznOWA32mo0y8')}
                    >
                        Basic – Beta
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ m: 1, backgroundColor: '#EECA74', color: '#000000' }}
                        onClick={() => handleSubscribe('price_pro')}
                    >
                        Pro
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ m: 1, backgroundColor: '#EECA74', color: '#000000' }}
                        onClick={() => handleSubscribe('price_enterprise')}
                    >
                        Enterprise
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default Billing;
