import React, { useState, useEffect } from 'react';
import Autocomplete from 'react-google-autocomplete';
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Card,
    CardHeader,
    CardContent,
    Grid,
    Chip,
    Tabs,
    Tab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    FormLabel,
    FormControlLabel,
    Radio,
    RadioGroup,
    Select,
    MenuItem,
    Checkbox
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const areaOfFocusOptions = [
    { title: 'Fashion', description: 'Showcasing clothing trends, styling tips, and wardrobe inspirations.' },
    { title: 'Beauty', description: 'Covering makeup tutorials, skincare routines, and product reviews.' },
    { title: 'Travel', description: 'Sharing destination guides, travel tips, and cultural experiences.' },
    { title: 'Food', description: 'Featuring recipes, restaurant reviews, and culinary adventures.' },
    { title: 'Fitness', description: 'Offering workout routines, health tips, and training advice.' },
    { title: 'Lifestyle', description: 'Highlighting daily living, personal growth, and home organization.' },
    { title: 'Technology', description: 'Reviewing gadgets, sharing tech news, and exploring innovations.' },
    { title: 'Gaming', description: 'Streaming gameplay, reviewing games, and discussing gaming culture.' },
    { title: 'Parenting', description: 'Providing family tips, parenting hacks, and kid-friendly content.' },
    { title: 'Home & Interior', description: 'Focusing on decor ideas, DIY projects, and interior design.' },
    { title: 'Photography', description: 'Offering tips, gear reviews, and showcasing photographic art.' },
    { title: 'Art & Design', description: 'Sharing creative work, design trends, and art tutorials.' },
    { title: 'Health & Wellness', description: 'Discussing mental health, nutrition, and holistic wellness.' },
    { title: 'Finance', description: 'Giving advice on saving, investing, and money management.' },
    { title: 'Music', description: 'Covering music reviews, instrument tutorials, and concert experiences.' },
    { title: 'DIY & Crafts', description: 'Showcasing handmade projects and creative crafting ideas.' },
    { title: 'Education', description: 'Providing learning resources, study tips, and educational content.' },
    { title: 'Comedy & Entertainment', description: 'Creating humorous sketches, parodies, and entertaining videos.' },
    { title: 'Sports', description: 'Analyzing games, training techniques, and athlete lifestyles.' },
    { title: 'Business & Entrepreneurship', description: 'Offering insights on startups, marketing, and career growth.' },
    { title: 'Outdoor & Adventure', description: 'Highlights camping, hiking, nature explorations, and adventurous outdoor activities.' },
    { title: 'Mindfulness & Meditation', description: 'Offers guidance on mindfulness practices, meditation techniques, and mental wellness strategies.' },
];

const InfluencerSearch = () => {
    // Main influencer data and UI state
    const [influencers, setInfluencers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [favorites, setFavorites] = useState([]);
    const [selectedInfluencer, setSelectedInfluencer] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [filterOpen, setFilterOpen] = useState(false);
    const [selectedSocials, setSelectedSocials] = useState([]);

    // New state for applied filter criteria
    const [appliedFilters, setAppliedFilters] = useState({
        tags: "",
        location: "",
        areaOfFocus: "",
        platform: [], // Array of selected platforms (e.g., ["YouTube", "Instagram"])
        followingCount: ""
    });


    useEffect(() => {
        fetch("/api/favorites")
            .then(res => res.json())
            .then(data => {
                const favIds = data.map(item => item.influencer_id);
                setFavorites(favIds);
            })
            .catch(err => console.error("Error fetching favorites:", err));
    }, []);


    // NEW CODE: When a selected influencer is set, fetch their socials
    useEffect(() => {
        const fetchInfluencersWithSocials = async () => {
            try {
                const response = await fetch("/api/influencers", { credentials: 'include' });
                const influencersData = await response.json();
                // For each influencer, fetch their socials and merge into the influencer object
                const influencersWithSocials = await Promise.all(
                    influencersData.map(async influencer => {
                        try {
                            const resSocial = await fetch(
                                `/api/getInfluencerSocials?influencerId=${influencer.user_id}`,
                                { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } }
                            );
                            const socialsData = await resSocial.json();
                            if (resSocial.ok && socialsData.success) {
                                return { ...influencer, social_accounts: socialsData.data };
                            } else {
                                console.error("Error fetching socials for influencer:", influencer.id, socialsData.error);
                                return { ...influencer, social_accounts: [] };
                            }
                        } catch (error) {
                            console.error("Error fetching socials for influencer:", influencer.id, error);
                            return { ...influencer, social_accounts: [] };
                        }
                    })
                );
                setInfluencers(influencersWithSocials);
            } catch (error) {
                console.error("Error fetching influencers:", error);
            }
        };

        fetchInfluencersWithSocials();
    }, []);



    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const toggleFavorite = (influencerId) => {
        if (favorites.includes(influencerId)) {
            fetch("/api/removeFavorites", {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ influencer_id: influencerId })
            })
                .then(res => {
                    if (res.ok) {
                        setFavorites(prev => prev.filter(id => id !== influencerId));
                    } else {
                        console.error("Error removing favorite");
                    }
                })
                .catch(err => console.error("Error removing favorite:", err));
        } else {
            fetch("/api/addFavorites", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ influencer_id: influencerId })
            })
                .then(res => {
                    if (res.ok) {
                        setFavorites(prev => [...prev, influencerId]);
                    } else {
                        console.error("Error adding favorite");
                    }
                })
                .catch(err => console.error("Error adding favorite:", err));
        }
    };

    // Base filtering: by search query (name or username)
    const baseFiltered = influencers.filter(influencer => {
        const fullName = `${influencer.first_name} ${influencer.last_name}`.toLowerCase();
        const username = influencer.username.toLowerCase();
        const query = searchQuery.toLowerCase();
        return fullName.includes(query) || username.includes(query);
    });

    // Best-match scoring function using filter criteria
    const applyBestMatchFilters = (list, filters) => {
        const filterTags = filters.tags
            ? filters.tags.split(',').map(tag => tag.trim().toLowerCase()).filter(Boolean)
            : [];
        const filterLocation = filters.location ? filters.location.trim().toLowerCase() : "";
        const filterArea = filters.areaOfFocus ? filters.areaOfFocus.trim().toLowerCase() : "";
        const filterPlatform = filters.platform.map(p => p.toLowerCase()) || [];
        const filterFollowing = filters.followingCount ? filters.followingCount.trim() : "";

        return list
            .map(influencer => {
                let score = 0;

                // Tag matching: +2 per exact match
                if (filterTags.length > 0 && influencer.tags) {
                    influencer.tags.forEach(tag => {
                        if (filterTags.includes(tag.toLowerCase())) {
                            score += 2;
                        }
                    });
                }

                // Location matching: +1 if influencer location contains filter location substring
                if (filterLocation && influencer.location && influencer.location.toLowerCase().includes(filterLocation)) {
                    score += 1;
                }

                // Area of Focus: +1 for exact match
                if (filterArea && influencer.area_of_focus && influencer.area_of_focus.toLowerCase() === filterArea) {
                    score += 1;
                }

                // NEW CODE: Platform Type filtering via social_accounts
                if (filterPlatform.length > 0 && influencer.social_accounts && influencer.social_accounts.length > 0) {
                    const matchingPlatform = influencer.social_accounts.some(social =>
                        filterPlatform.includes(social.platform.toLowerCase())
                    );
                    if (matchingPlatform) {
                        score += 1;
                    }
                }

                // NEW CODE: Following Count filtering via social_accounts using range
                if (filterFollowing && influencer.social_accounts && influencer.social_accounts.length > 0) {
                    const matchingFollowing = influencer.social_accounts.some(social => {
                        const normalizedSocialCount = normalizeFollowerCount(social.follower_count);
                        return isWithinRange(normalizedSocialCount, filterFollowing);
                    });
                    if (matchingFollowing) {
                        score += 1;
                    }
                }



                return { influencer, score };
            })
            .sort((a, b) => {
                if (b.score === a.score) {
                    const nameA = `${a.influencer.first_name} ${a.influencer.last_name}`.toLowerCase();
                    const nameB = `${b.influencer.first_name} ${b.influencer.last_name}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                }
                return b.score - a.score;
            })
            .map(item => item.influencer);
    };

    const normalizeFollowerCount = (countStr) => {
        if (!countStr) return 0;
        // Remove commas, trim whitespace, and convert to lower case
        const s = countStr.replace(/,/g, '').trim().toLowerCase();
        if (s.endsWith('k')) {
            const num = parseFloat(s.slice(0, -1));
            return num * 1000;
        } else if (s.endsWith('m')) {
            const num = parseFloat(s.slice(0, -1));
            return num * 1000000;
        } else {
            return parseFloat(s);
        }
    };

    const isWithinRange = (normalizedCount, rangeStr) => {
        // Convert the filter string to lowercase for consistency
        switch(rangeStr.toLowerCase()) {
            case "1k-10k":
                return normalizedCount >= 1000 && normalizedCount < 10000;
            case "10k-50k":
                return normalizedCount >= 10000 && normalizedCount < 50000;
            case "50k-250k":
                return normalizedCount >= 50000 && normalizedCount < 250000;
            case "250k-1m":
                return normalizedCount >= 250000 && normalizedCount < 1000000;
            case "1m+":
                return normalizedCount >= 1000000;
            default:
                return false;
        }
    };



    // Determine final filtered influencers
    const finalFilteredInfluencers =
        appliedFilters.tags || appliedFilters.location || appliedFilters.areaOfFocus || (appliedFilters.platform && appliedFilters.platform.length > 0) || appliedFilters.followingCount
            ? applyBestMatchFilters(baseFiltered, appliedFilters)
            : baseFiltered;

    const favoriteInfluencers = influencers.filter(influencer =>
        favorites.includes(influencer.id)
    );

    const handleSelectInfluencer = (influencer) => {
        setSelectedInfluencer(influencer);
    };

    const handleBack = () => {
        setSelectedInfluencer(null);
    };

    // Render list function (same as before, with extra details)
    const renderList = (list) => (
        <List>
            {list.map((influencer) => (
                <ListItem
                    key={influencer.id}
                    divider
                    button
                    onClick={() => handleSelectInfluencer(influencer)}
                    sx={{
                        backgroundColor: '#1e1e1e',
                        borderRadius: '8px',
                        mb: 2,
                        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                        '&:hover': {
                            transform: 'scale(1.02)',
                            boxShadow: '0px 4px 10px rgba(0,0,0,0.3)',
                        },
                    }}
                >
                    <ListItemText
                        primary={
                            <Typography variant="h6" sx={{ color: '#EECA74' }}>
                                {influencer.first_name} {influencer.last_name}
                            </Typography>
                        }
                        secondary={
                            <>
                                <Typography variant="body2" sx={{ color: '#AAAAAA' }}>
                                    {influencer.username}
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#AAAAAA' }}>
                                    Location: {influencer.location || 'N/A'}
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#AAAAAA' }}>
                                    Area of Focus: {influencer.area_of_focus || 'N/A'}
                                </Typography>
                                {influencer.tags && influencer.tags.length > 0 && (
                                    <Box sx={{ mt: 0.5 }}>
                                        {influencer.tags.map((tag, idx) => (
                                            <Chip
                                                key={idx}
                                                label={tag}
                                                size="small"
                                                sx={{
                                                    mr: 0.5,
                                                    backgroundColor: '#EECA74',
                                                    color: '#000000',
                                                }}
                                            />
                                        ))}
                                    </Box>
                                )}
                            </>
                        }
                        sx={{
                            '& .MuiListItemText-primary': { color: '#EECA74' },
                            '& .MuiListItemText-secondary': { color: '#AAAAAA' },
                        }}
                    />
                    <ListItemSecondaryAction>
                        <IconButton
                            edge="end"
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleFavorite(influencer.id);
                            }}
                        >
                            {favorites.includes(influencer.id) ? (
                                <StarIcon sx={{ color: '#EECA74' }} />
                            ) : (
                                <StarBorderIcon sx={{ color: '#EECA74' }} />
                            )}
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );

    // Filter Popup Component
    const FilterPopup = ({ open, onClose, onApply }) => {
        // Local state for filter fields
        const [tags, setTags] = useState('');
        const [location, setLocation] = useState('');
        const [areaOfFocus, setAreaOfFocus] = useState('');
        const [followingCount, setFollowingCount] = useState('');
        const [platform, setPlatform] = useState([]); // Multiple options allowed

        const handlePlatformChange = (value, event) => {
            if (event.target.checked) {
                setPlatform(prev => [...prev, value]);
            } else {
                setPlatform(prev => prev.filter(item => item !== value));
            }
        };

        const handleApply = () => {
            onApply({
                tags,
                location,
                areaOfFocus,
                followingCount,
                platform
            });
            onClose();
        };

        return (
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
                <DialogTitle sx={{ color: '#EECA74', backgroundColor: '#121212' }}>
                    Filter Influencers
                </DialogTitle>
                <DialogContent sx={{ backgroundColor: '#121212' }}>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            label="Influencer Tags"
                            variant="outlined"
                            fullWidth
                            sx={{
                                mb: 2,
                                '& .MuiInputLabel-root': { color: '#FFFFFF' },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: '#EECA74' },
                                    '&:hover fieldset': { borderColor: '#EECA74' },
                                    '&.Mui-focused fieldset': { borderColor: '#EECA74' },
                                    '& input': { color: '#FFFFFF' },
                                }
                            }}
                            value={tags}
                            onChange={(e) => setTags(e.target.value)}
                            helperText="Enter tags separated by commas"
                        />
                        <TextField
                            label="Location"
                            variant="outlined"
                            fullWidth
                            sx={{
                                mb: 2,
                                '& .MuiInputLabel-root': { color: '#FFFFFF' },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: '#EECA74' },
                                    '&:hover fieldset': { borderColor: '#EECA74' },
                                    '&.Mui-focused fieldset': { borderColor: '#EECA74' },
                                    '& input': { color: '#FFFFFF' },
                                }
                            }}
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            InputProps={{
                                inputComponent: Autocomplete,
                                inputProps: {
                                    apiKey: "AIzaSyAcSt2xik5cqZsFHltHmj2DWxdqSXFybT0",
                                    options: {
                                        types: ['(cities)'],
                                        componentRestrictions: { country: 'us' },
                                    },
                                    onPlaceSelected: (place) => {
                                        if (!place.address_components) return;
                                        const city = place.address_components.find(component =>
                                            component.types.includes('locality')
                                        )?.long_name;
                                        const state = place.address_components.find(component =>
                                            component.types.includes('administrative_area_level_1')
                                        )?.short_name;
                                        if (city && state) {
                                            setLocation(`${city}, ${state}`);
                                        } else {
                                            setLocation(place.formatted_address);
                                        }
                                    },
                                    style: {
                                        fontSize: '16px',
                                        color: '#FFFFFF',
                                    },
                                },
                            }}
                        />

                        {/* Area of Interest Field */}
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <FormLabel sx={{ color: '#EECA74' }}>Area of Interest</FormLabel>
                            <Select
                                value={areaOfFocus}
                                onChange={(e) => setAreaOfFocus(e.target.value)}
                                displayEmpty
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#121212',
                                            border: '1px solid #EECA74'
                                        }
                                    }
                                }}
                                sx={{
                                    color: '#FFFFFF',
                                    backgroundColor: '#121212',
                                    '& .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' },
                                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' },
                                    '& .MuiSelect-icon': { color: '#FFFFFF' }
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {areaOfFocusOptions.map((option, index) => (
                                    <MenuItem key={index} value={option.title}>
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Platform Type (Checkboxes) */}
                        <FormControl component="fieldset" sx={{ mb: 2, width: '100%', color: '#FFFFFF' }}>
                            <FormLabel component="legend" sx={{ color: '#EECA74' }}>
                                Platform Type
                            </FormLabel>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={platform.includes("YouTube")}
                                            onChange={(e) => handlePlatformChange("YouTube", e)}
                                            sx={{ color: '#EECA74' }}
                                        />
                                    }
                                    label="YouTube"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={platform.includes("TikTok")}
                                            onChange={(e) => handlePlatformChange("TikTok", e)}
                                            sx={{ color: '#EECA74' }}
                                        />
                                    }
                                    label="TikTok"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={platform.includes("Instagram")}
                                            onChange={(e) => handlePlatformChange("Instagram", e)}
                                            sx={{ color: '#EECA74' }}
                                        />
                                    }
                                    label="Instagram"
                                />
                            </Box>
                        </FormControl>

                        {/* Following Count (Radio Group) */}
                        <FormControl component="fieldset" sx={{ mb: 2, width: '100%', color: '#FFFFFF' }}>
                            <FormLabel component="legend" sx={{ color: '#EECA74' }}>
                                Following Count
                            </FormLabel>
                            <RadioGroup value={followingCount} onChange={(e) => setFollowingCount(e.target.value)}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <FormControlLabel value="1k-10k" control={<Radio sx={{ color: '#EECA74' }} />} label="1k-10k" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel value="10k-50k" control={<Radio sx={{ color: '#EECA74' }} />} label="10k-50k" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel value="50k-250k" control={<Radio sx={{ color: '#EECA74' }} />} label="50k-250k" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel value="250k-1M" control={<Radio sx={{ color: '#EECA74' }} />} label="250k-1M" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel value="1M+" control={<Radio sx={{ color: '#EECA74' }} />} label="1M+" />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#121212' }}>
                    <Button onClick={onClose} sx={{ color: '#EECA74' }}>Cancel</Button>
                    <Button onClick={handleApply} variant="contained" sx={{ color: '#000000', backgroundColor: '#EECA74' }}>
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    // When filters are applied, update the appliedFilters state
    const handleApplyFilters = (filters) => {
        setAppliedFilters(filters);
    };

    return (
        <Box
            sx={{
                maxWidth: 800,
                mx: 'auto',
                p: 3,
                backgroundColor: '#121212',
                color: '#FFFFFF',
                minHeight: '100vh',
                borderRadius: '8px',
                boxShadow: 3,
            }}
        >
            <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', color: '#EECA74' }}>
                Influencer Search
            </Typography>

            {/* Tabs for "Search" and "Favorites" */}
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                textColor="inherit"
                indicatorColor="primary"
                sx={{ mb: 2, justifyContent: 'center' }}
            >
                <Tab label="Search" />
                <Tab label="Favorites" />
            </Tabs>

            {selectedInfluencer ? (
                <Card sx={{ mt: 4, backgroundColor: '#1e1e1e', color: '#FFFFFF', borderRadius: '8px', boxShadow: 3 }}>
                    <CardHeader
                        title={`${selectedInfluencer.first_name} ${selectedInfluencer.last_name}`}
                        subheader={selectedInfluencer.username}
                        sx={{
                            '& .MuiCardHeader-title': { color: '#EECA74' },
                            '& .MuiCardHeader-subheader': { color: '#FFFFFF' }
                        }}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1">
                                    <strong>Phone:</strong> {selectedInfluencer.phone_number || "N/A"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1">
                                    <strong>Location:</strong> {selectedInfluencer.location || "N/A"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    <strong>About:</strong> {selectedInfluencer.about || "N/A"}
                                </Typography>
                            </Grid>
                            {/* NEW CODE: Display Area of Focus */}
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    <strong>Area of Focus:</strong> {selectedInfluencer.area_of_focus || "N/A"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Tags:</strong>
                                </Typography>
                                {selectedInfluencer.tags && selectedInfluencer.tags.length > 0 ? (
                                    selectedInfluencer.tags.map((tag, index) => (
                                        <Chip
                                            key={index}
                                            label={tag}
                                            sx={{ mr: 1, mb: 1, backgroundColor: '#EECA74', color: '#000000' }}
                                        />
                                    ))
                                ) : (
                                    <Typography variant="body2">No tags available</Typography>
                                )}
                            </Grid>
                            {/* Display Social Accounts */}
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Social Accounts:</strong>
                                </Typography>
                                {selectedInfluencer.social_accounts && selectedInfluencer.social_accounts.length > 0 ? (
                                    selectedInfluencer.social_accounts.map((social) => {
                                        let linkUrl = social.account_url;
                                        if (social.platform.toLowerCase() === 'instagram') {
                                            linkUrl = social.account_url.replace('/@', '/');
                                        }
                                        return (
                                            <Typography key={social.id} variant="body2" sx={{ mb: 0.5 }}>
                                                <strong>{social.platform}:</strong>{' '}
                                                <a
                                                    href={linkUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ color: '#EECA74', textDecoration: 'underline' }}
                                                >
                                                    {social.username}
                                                </a>{' '}
                                                (<span style={{ color: '#AAAAAA' }}>{social.follower_count}</span>)
                                            </Typography>
                                        );
                                    })
                                ) : (
                                    <Typography variant="body2">No social accounts available</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Button
                            variant="outlined"
                            sx={{ mt: 2, borderColor: '#EECA74', color: '#EECA74' }}
                            onClick={handleBack}
                        >
                            Back to Search Results
                        </Button>
                    </CardContent>
                </Card>

            ) : (
                <>
                    {activeTab === 0 && (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <TextField
                                    label="Search Influencers"
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        mr: 2,
                                        '& .MuiInputLabel-root': { color: '#FFFFFF' },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': { borderColor: '#EECA74' },
                                            '&:hover fieldset': { borderColor: '#EECA74' },
                                            '&.Mui-focused fieldset': { borderColor: '#EECA74' },
                                            '& input': { color: '#FFFFFF' },
                                        }
                                    }}
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#EECA74',
                                        color: '#000000'
                                    }}
                                >
                                    Search
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        ml: 2,
                                        borderColor: '#EECA74',
                                        color: '#EECA74'
                                    }}
                                    onClick={() => setFilterOpen(true)}
                                >
                                    Filter
                                </Button>
                            </Box>
                            {renderList(finalFilteredInfluencers)}
                        </>
                    )}
                    {activeTab === 1 && (
                        <>
                            {renderList(favoriteInfluencers)}
                        </>
                    )}
                </>
            )}

            <FilterPopup open={filterOpen} onClose={() => setFilterOpen(false)} onApply={handleApplyFilters} />
        </Box>
    );
};

export default InfluencerSearch;
