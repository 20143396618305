import React from 'react';
import {Button} from "@mui/material";

const InfluencerSearchButton = ({ jobId, onSearchClick }) => {
    return (
        <Button variant="contained" onClick={() => onSearchClick(jobId)}>
            Influencer Search
        </Button>
    );
};

export default InfluencerSearchButton;

/*
* update influencer display
* <List sx={{ maxHeight: '200px', overflowY: 'auto', backgroundColor: '#1A1A1A', borderRadius: '8px' }}>
    {filteredInfluencers.map((influencer) => (
        <ListItem
            key={influencer.id}
            button
            selected={selectedInfluencerId === influencer.id}
            onClick={() => setSelectedInfluencerId(influencer.id)}
            sx={{
                '&.Mui-selected': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.text.primary,
                },
                '&.Mui-selected:hover': {
                    backgroundColor: theme.palette.primary.main,
                },
                '&:hover': {
                    backgroundColor: '#2A2A2A',
                },
            }}
        >
            <ListItemText
                primary={`${influencer.title} (${influencer.matchCount} matching tags)`}
            />
        </ListItem>
    ))}
</List>

*
* update fetch login in influencer search
* useEffect(() => {
    const fetchInfluencers = async () => {
        try {
            const response = await fetch(`/api/influencers?jobId=${jobId}`, {
                method: 'GET',
                credentials: 'include',
            });
            const data = await response.json();
            setInfluencers(data);
            setFilteredInfluencers(data);
        } catch (error) {
            console.error('Error fetching influencers in influencerSearch:', error);
        }
    };

    fetchInfluencers();
}, [jobId]);
*
*
* */
