import React from 'react';
import { Typography, Box, Badge, Avatar } from '@mui/material';
import WavingHandIcon from '@mui/icons-material/WavingHand';

function WelcomeBoard({ userName }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2, marginBottom: 5 }}>
            {/* Avatar with a waving hand icon */}
            <Avatar
                sx={{
                    marginRight: 2,
                    marginTop: 2,
                    width: 56,
                    height: 56,
                    backgroundColor: '#EECA74', // Gold background for avatar
                    color: '#000', // Black color for icon
                }}
            >
                <WavingHandIcon fontSize="large" />
            </Avatar>

            {/* Welcome Message */}
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                Welcome, {userName}{' '}
                <Badge color="primary">
                    <WavingHandIcon color="action" fontSize="large" />
                </Badge>
            </Typography>
        </Box>
    );
}

export default WelcomeBoard;
