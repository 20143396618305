import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/authContext';
import {
    Typography,
    Box,
    Drawer,
    Tabs,
    Tab,
    IconButton,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import PushPinIcon from '@mui/icons-material/PushPin';
import CampaignIcon from '@mui/icons-material/Campaign';
import SearchIcon from '@mui/icons-material/Search';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MessageIcon from '@mui/icons-material/Message';
import Person2Icon from '@mui/icons-material/Person2';
import PaymentIcon from '@mui/icons-material/Payment';
import WorkIcon from '@mui/icons-material/Work';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import WelcomeBoard from './WelcomeBoard.jsx';
import CompanyTrends from './CompanyTrends.jsx';
import BrandDealTableCompany from './BrandDealTableCompany.jsx';
import PostJobPage from '../../pages/job-manager/JobManagerPage';
import CampaignPage from '../campaign/CampaignPage';
import InfluencerSearch from '../influencerBoard/InfluencerSearch';
import CalendarPage from '../calendar/CalendarPage';
import Messenger from '../messenger/messenger';
import ProfilePage from '../profile_page';
import Billing from '../billing/Billing';
import JobOffersPage from '../JobOffersPage';
import InfluencerTrends from './InfluencerTrends.jsx';
import BrandDealTableInfluencer from './BrandDealTableInfluencer.jsx';
import JobBoard from '../jobBoard/JobBoard';

const Home = () => {
    const { currentUser, loading } = useAuth();
    const [userType, setUserType] = useState('');
    const [userData, setUserData] = useState({});
    const [selectedTab, setSelectedTab] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        if (loading || !currentUser) return;
        const handleRender = async () => {
            try {
                const response = await fetch('/api/getUserType', {
                    method: 'GET',
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    setUserType(data.userType);
                    setUserData(data.userData);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
        handleRender();
    }, [currentUser, loading]);

    const handleTabChange = (event, newValue) => {
        const tab = filteredTabs[newValue];
        if (tab.comingSoon) return;
        setSelectedTab(newValue);
    };

    const companyTabs = [
        { label: 'Dashboard', icon: <SpaceDashboardIcon /> },
        { label: 'Post Job', icon: <PushPinIcon /> },
        { label: 'Campaign', icon: <CampaignIcon />, comingSoon: true },
        { label: 'Influencer Search', icon: <SearchIcon /> },
        { label: 'Calendar', icon: <CalendarTodayIcon /> },
        { label: 'Messages', icon: <MessageIcon /> },
        { label: 'Profile', icon: <Person2Icon /> },
        { label: 'Billing', icon: <PaymentIcon />, comingSoon: true },
    ];

    const influencerTabs = [
        { label: 'Dashboard', icon: <SpaceDashboardIcon /> },
        { label: 'Job Offers', icon: <WorkIcon /> },
        { label: 'Job Board', icon: <ContentPasteIcon /> },
        { label: 'Calendar', icon: <CalendarTodayIcon /> },
        { label: 'Messages', icon: <MessageIcon /> },
        { label: 'Profile', icon: <Person2Icon /> },

    ];

    const filteredTabs = userType === 'company' ? companyTabs : influencerTabs;

    const renderDrawerContent = () => (
        <Box sx={{ flexGrow: 1, p: 3, mt: 8 }}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={selectedTab}
                onChange={handleTabChange}
                sx={{
                    color: 'white',
                    '& .Mui-selected': { color: '#EECA74' },
                    '& .MuiTabs-indicator': { backgroundColor: '#EECA74' },
                }}
            >
                {filteredTabs.map((tab, index) => (
                    <Tab
                        key={index}
                        icon={tab.icon}
                        label={
                            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                {tab.label}
                                {tab.comingSoon && (
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            position: 'absolute',
                                            right: 0,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            backgroundColor: 'rgba(0,0,0,0.7)',
                                            color: '#EECA74',
                                            padding: '2px 6px',
                                            borderRadius: '4px',
                                            fontWeight: 'bold',
                                            fontSize: '0.75rem',
                                            zIndex: 1,
                                        }}
                                    >
                                        Coming Soon
                                    </Typography>
                                )}
                            </Box>
                        }
                        sx={{
                            color: selectedTab === index ? '#EECA74' : tab.comingSoon ? 'gray' : 'white',
                            opacity: tab.comingSoon ? 0.6 : 1,
                            '&:hover': {
                                color: tab.comingSoon ? 'gray' : '#EECA74',
                            },
                        }}
                    />
                ))}
            </Tabs>
        </Box>
    );

    const renderTabContent = () => {
        if (!currentUser) return <Typography variant="h5">Loading...</Typography>;

        if (userType === 'company') {
            switch (selectedTab) {
                case 0:
                    return (
                        <>
                            <WelcomeBoard userName={userData.name || userData.userName || currentUser.email} />
                            <CompanyTrends />
                            <BrandDealTableCompany id={currentUser.id} />
                        </>
                    );
                case 1: return <PostJobPage />;
                case 2: return <CampaignPage />;
                case 3: return <InfluencerSearch />;
                case 4: return <CalendarPage />;
                case 5: return <Messenger />;
                case 6: return <ProfilePage />;
                case 7: return <Billing />;
                default: return <Typography variant="h5">Select a tab</Typography>;
            }
        } else {
            switch (selectedTab) {
                case 0:
                    return (
                        <>
                            <WelcomeBoard userName={userData.name || userData.userName || currentUser.email} />
                            <InfluencerTrends />
                            <BrandDealTableInfluencer id={currentUser.id} />
                        </>
                    );
                case 1: return <JobOffersPage />;
                case 2: return <JobBoard influencerTags={userData.tags || []} />;
                case 3: return <CalendarPage />;
                case 4: return <Messenger />;
                case 5: return <ProfilePage />;
                default: return <Typography variant="h5">Select a tab</Typography>;
            }
        }
    };

    if (loading) {
        return (
            <Box sx={{ alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
                <Typography variant="h3" fontWeight="fontWeightLight" textAlign="center">
                    Loading your account...
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {isMobile && (
                <IconButton
                    onClick={handleDrawerToggle}
                    sx={{
                        position: 'fixed',
                        top: 65,
                        left: 29,
                        zIndex: 1300,
                        backgroundColor: '#000',
                        color: '#EECA74',
                        border: '1px solid #EECA74',
                        '&:hover': { backgroundColor: '#1a1a1a' },
                    }}
                >
                    <MenuIcon />
                </IconButton>
            )}

            {isMobile ? (
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        [`& .MuiDrawer-paper`]: {
                            width: 240,
                            backgroundColor: '#000',
                            color: 'white',
                            boxShadow: '0px 0px 10px 2px #EECA74',
                            borderRight: '1px solid #EECA74',
                            top: 64, // ⬅️ Add this to push drawer below header (adjust if needed)
                        },
                    }}
                >
                    {renderDrawerContent()}
                </Drawer>
            ) : (
                <Drawer
                    variant="permanent"
                    sx={{
                        width: 240,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: 240,
                            backgroundColor: '#000',
                            color: 'white',
                            boxShadow: '0px 0px 10px 2px #EECA74',
                            borderRight: '1px solid #EECA74',
                        },
                    }}
                >
                    {renderDrawerContent()}
                </Drawer>
            )}

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                {renderTabContent()}
            </Box>
        </Box>
    );
};

export default Home;
