import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../../contexts/authContext';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import NewChatRoomDialog from './newChatRoomDialog';
import MessageInput from './messageInput';
import CommonFrame from '../CommonFrame';
import moment from 'moment-timezone';


function Messenger() {
  const { currentUser } = useAuth();
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [chatRooms, setChatRooms] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [renderTrigger, setRenderTrigger] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Fetch chat rooms for the current user
  useEffect(() => {
    const fetchChatRooms = async () => {
      try {
        const response = await fetch('/api/getChatRoomsForUser');
        if (!response.ok) throw new Error('Failed to fetch chat rooms');
        const data = await response.json();
        setChatRooms(Array.isArray(data) ? data : []);
        if (Array.isArray(data) && data.length > 0 && !selectedContactId) {
          setSelectedContactId(data[0].id);
        }
      } catch (error) {
        console.error('Failed to fetch chat rooms:', error);
      }
    };
    fetchChatRooms();
  }, [renderTrigger]);

  // Map chat rooms to contacts (each with an id and display name)
  useEffect(() => {
    if (!currentUser) return;
    const newContacts = Array.isArray(chatRooms)
        ? chatRooms.map(room => {
          // If currentUser is user1, then the partner is user2; otherwise, it's user1.
          const contactName =
              currentUser.id === room.user1_id ? room.user2_name : room.user1_name;
          return { id: room.id, name: contactName };
        })
        : [];
    setContacts(newContacts);
  }, [chatRooms, currentUser]);

  // Update messages whenever the selected chat room changes.
  useEffect(() => {
    const selectedChatRoom = Array.isArray(chatRooms)
        ? chatRooms.find(room => room.id === selectedContactId)
        : null;
    if (selectedChatRoom && selectedChatRoom.messages) {
      // Unwrap messages (assuming each item has a "message" property)
      const unwrappedMessages = selectedChatRoom.messages.map(item => item.message);
      setMessages(unwrappedMessages);
    } else {
      setMessages([]);
    }
  }, [selectedContactId, chatRooms]);

  const handleContactClick = (roomId) => {
    setSelectedContactId(roomId);
  };



  // Delete a chat room by calling the API endpoint
  const handleDeleteChatRoom = async (contactId, e) => {
    e.stopPropagation(); // Prevent selecting the contact when clicking delete
    try {
      const response = await fetch('/api/deleteChatRoom', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ chatRoomId: contactId }),
      });
      if (!response.ok) throw new Error('Failed to delete chat room');
      setRenderTrigger(prev => !prev); // Refresh the list
      if (selectedContactId === contactId) {
        setSelectedContactId(null);
      }
    } catch (error) {
      console.error('Error deleting chat room:', error);
    }
  };

  // Determine which contact is selected
  const selectedContact = Array.isArray(contacts)
      ? contacts.find(contact => contact.id === selectedContactId)
      : null;

  const messagesEndRef = useRef(null);

  return (
      <CommonFrame
          items={[]} // Pass an empty array so CommonFrame doesn't render its own list
          // Custom sidebar content in the searchBar prop:
          searchBar={
            <Box sx={{ p: 2, backgroundColor: '#000000' }}>
              <Button
                  variant="outlined"
                  sx={{ color: '#EECA74', borderColor: '#EECA74', mb: 2 }}
                  onClick={() => setDialogOpen(true)}
              >
                Start New Chatroom
              </Button>
              <NewChatRoomDialog
                  open={dialogOpen}
                  setOpen={setDialogOpen}
                  renderTrigger={renderTrigger}
                  setRenderTrigger={setRenderTrigger}
                  setSelectedContactId={setSelectedContactId}
              />
              <Typography variant="h6" sx={{ color: '#FFFFFF', mb: 1 }}>
                Chats
              </Typography>
              <List>
                {Array.isArray(contacts) &&
                    contacts.map(contact => (
                        <ListItem
                            key={contact.id}
                            divider
                            button
                            onClick={() => handleContactClick(contact.id)}
                            sx={{ backgroundColor: '#000000' }}
                        >
                          <ListItemText
                              primary={contact.name}
                              sx={{ '& .MuiListItemText-primary': { color: '#FFFFFF' } }}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                onClick={(e) => handleDeleteChatRoom(contact.id, e)}
                                sx={{ color: '#EECA74' }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                    ))}
              </List>
            </Box>
          }
      >
        {/* Main Chat Area */}
        <Box sx={{ flexGrow: 1, p: 3, backgroundColor: '#000000', height: '80vh' }}>
          <AppBar position="static" sx={{ backgroundColor: '#000000', borderBottom: '1px solid #EECA74' }}>
            <Toolbar>
              <Typography variant="h6" noWrap component="div" sx={{ color: '#EECA74' }}>
                Messenger
              </Typography>
            </Toolbar>
          </AppBar>

          {selectedContact && (
              <Box sx={{ p: 2, backgroundColor: '#000000' }}>
                <Typography variant="h6" sx={{ color: '#FFFFFF' }}>
                  Chat with {selectedContact.name}
                </Typography>
              </Box>
          )}

          <Paper
              style={{
                height: 'calc(75% - 64px)',
                overflow: 'auto',
                marginTop: '24px',
                backgroundColor: '#000000'
              }}
          >
            <List>
              {Array.isArray(messages) &&
                  messages.map((message) => (
                      <ListItem
                          key={message.id}
                          alignItems="flex-start"
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: message.sender === currentUser.id ? 'flex-end' : 'flex-start',
                            mb: 1,
                          }}
                      >
                        <Box
                            sx={{
                              maxWidth: '70%',
                              backgroundColor: message.sender === currentUser.id ? '#8e44ad' : '#7e7e7e',
                              color: '#FFFFFF',
                              borderRadius: '10px',
                              p: 1,
                              ml: message.sender === currentUser.id ? 'auto' : 0,
                              boxShadow: 1,
                              textAlign: message.sender === currentUser.id ? 'right' : 'left',
                            }}
                        >
                          {/* Message text */}
                          <Typography variant="body1">
                            {message.text}
                          </Typography>
                          {/* Message date/time */}
                          <Typography
                              variant="caption"
                              sx={{
                                display: 'block',
                                marginTop: '4px',
                                color: '#CCCCCC',
                                textAlign: message.sender === currentUser.id ? 'right' : 'left',
                              }}
                          >
                            {moment.utc(message.date).tz(moment.tz.guess()).format('MM/DD/YYYY, h:mm A')}
                          </Typography>
                        </Box>
                      </ListItem>
                  ))}
              {/* Dummy element for scrolling to bottom */}
              <div ref={messagesEndRef} />
            </List>
          </Paper>
          {selectedContactId && (
              <MessageInput
                  selectedRoomId={selectedContactId}
                  renderTrigger={renderTrigger}
                  setRenderTrigger={setRenderTrigger}
              />
          )}
        </Box>
      </CommonFrame>
  );
}

export default Messenger;
