import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../../supabase/supabaseClient";
import { Box, TextField, Button, Typography, Alert } from "@mui/material";

const UpdatePassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handlePasswordUpdate = async () => {
        setErrorMessage(""); // Clear previous errors

        // Check if passwords match
        if (newPassword !== confirmPassword) {
            setErrorMessage("Passwords do not match. Please try again.");
            return;
        }

        // Check for minimum password length (e.g., 6 characters)
        if (newPassword.length < 6) {
            setErrorMessage("Password must be at least 6 characters long.");
            return;
        }

        setLoading(true);

        // Attempt to update the password
        const { error: updateError } = await supabase.auth.updateUser({ password: newPassword });

        if (updateError) {
            setLoading(false);
            setErrorMessage(`Failed to reset password: ${updateError.message}`);
            return;
        }

        // If password update is successful, log the user out
        const { error: signOutError } = await supabase.auth.signOut();

        setLoading(false);

        if (signOutError) {
            setErrorMessage(`Password updated, but failed to log out: ${signOutError.message}`);
        } else {
            alert("Password successfully updated! Please log in again.");
            navigate("/login");
        }
    };

    return (
        <Box
            sx={{
                maxWidth: 400,
                mx: "auto",
                mt: 5,
                textAlign: "center",
                backgroundColor: "black",
                padding: 3,
                borderRadius: "12px",
                border: "2px solid #EECA74",
                color: "#EECA74"
            }}
        >
            <Typography variant="h5" sx={{ color: "#EECA74", mb: 2 }}>
                Set a New Password
            </Typography>

            {errorMessage && (
                <Alert severity="error" sx={{ mb: 2, backgroundColor: "black", color: "#EECA74", border: "1px solid #EECA74" }}>
                    {errorMessage}
                </Alert>
            )}

            <TextField
                label="New Password"
                type="password"
                fullWidth
                margin="normal"
                variant="outlined"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                InputLabelProps={{ style: { color: "#EECA74" } }}
                InputProps={{
                    style: { color: "#FFFFFF" }, // Makes the text inside the field white
                    sx: {
                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' }
                    }
                }}
            />

            <TextField
                label="Confirm Password"
                type="password"
                fullWidth
                margin="normal"
                variant="outlined"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputLabelProps={{ style: { color: "#EECA74" } }}
                InputProps={{
                    style: { color: "#FFFFFF" }, // Makes the text inside the field white
                    sx: {
                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#EECA74' }
                    }
                }}
            />

            <Button
                variant="contained"
                fullWidth
                sx={{ mt: 2, backgroundColor: "#EECA74", color: "black", '&:hover': { backgroundColor: "#EECA74" } }}
                onClick={handlePasswordUpdate}
                disabled={loading}
            >
                {loading ? "Updating..." : "Update Password"}
            </Button>
        </Box>
    );
};

export default UpdatePassword;
