import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';



const InfluencerSearch = ({ jobId, onOfferSent }) => {
    const theme = useTheme(); // Access the theme from App.js
    const [influencers, setInfluencers] = useState([]);
    const [filteredInfluencers, setFilteredInfluencers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedInfluencerId, setSelectedInfluencerId] = useState(null);
    const [offerMessage, setOfferMessage] = useState('');
    const [selectedInfluencerInfo, setSelectedInfluencerInfo] = useState(null);
    const [infoOpen, setInfoOpen] = useState(false);
    const [socials, setSocials] = useState([]);
    const [favorites, setFavorites] = useState([]);



    // Fetch influencers when the component mounts
    useEffect(() => {
        const fetchInfluencersAndFavorites = async () => {
            try {
                const [infRes, favRes] = await Promise.all([
                    fetch('/api/influencers', { credentials: 'include' }),
                    fetch('/api/favorites', { credentials: 'include' }),
                ]);

                const infData = await infRes.json();
                const favData = await favRes.ok ? await favRes.json() : [];

                const favoritesIds = favData.map(fav => fav.influencer_id);
                setFavorites(favoritesIds);

                const formattedData = infData.map((influencer) => ({
                    ...influencer,
                    title: `${influencer.first_name} ${influencer.last_name}`,
                }));

                const sorted = [
                    ...formattedData.filter(inf => favoritesIds.includes(inf.id)),
                    ...formattedData.filter(inf => !favoritesIds.includes(inf.id)),
                ];

                setInfluencers(formattedData);
                setFilteredInfluencers(sorted);
            } catch (error) {
                console.error('Error fetching influencers or favorites:', error);
            }
        };

        fetchInfluencersAndFavorites();
    }, []);


    // Update filtered influencers whenever searchTerm changes
    useEffect(() => {
        const filtered = influencers.filter((influencer) =>
            influencer.title.toLowerCase().includes(searchTerm.toLowerCase())
        );

        const sorted = [
            ...filtered.filter(inf => favorites.includes(inf.id)),
            ...filtered.filter(inf => !favorites.includes(inf.id)),
        ];

        setFilteredInfluencers(sorted);
    }, [searchTerm, influencers, favorites]);


    const handleSendOffer = async () => {
        if (selectedInfluencerId) {
            const response = await fetch('/api/sendOffer', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    influencer_id: selectedInfluencerId,
                    job_id: jobId,
                    message: offerMessage,
                }),
                credentials: 'include',
            });

            if (response.ok) {
                alert('Offer sent successfully');
                setOfferMessage('');
                onOfferSent(); // Notify parent component to refresh or update state
            } else {
                alert('Failed to send offer');
            }
        } else {
            alert('Please select an influencer first.');
        }
    };

    return (
        <Box sx={{ padding: '20px', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <Typography variant="h5" sx={{ mb: 3 }}>
                Influencer Search
            </Typography>
            <TextField
                label="Search for an influencer..."
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: theme.palette.text.primary,
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.primary.main,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.primary.main,
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: theme.palette.text.primary,
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: theme.palette.primary.main,
                    },
                }}
            />
            <List sx={{ maxHeight: '200px', overflowY: 'auto', backgroundColor: '#1A1A1A', borderRadius: '8px' }}>
                {filteredInfluencers.map((influencer) => (
                    <ListItem
                        key={influencer.id}
                        button
                        selected={selectedInfluencerId === influencer.id}
                        onClick={() => setSelectedInfluencerId(influencer.id)}
                        sx={{
                            '&.Mui-selected': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.text.primary,
                            },
                            '&.Mui-selected:hover': {
                                backgroundColor: theme.palette.primary.main,
                            },
                            '&:hover': {
                                backgroundColor: '#2A2A2A',
                            },
                        }}
                    >
                        <ListItemText
                            primary={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {influencer.title}
                                    {favorites.includes(influencer.id) && (
                                        <StarIcon sx={{ color: '#EECA74', ml: 1, fontSize: '1.1rem' }} />
                                    )}
                                </Box>
                            }
                        />
                        <IconButton
                            onClick={async (e) => {
                                e.stopPropagation();

                                try {
                                    console.log("Fetching socials for user_id:", influencer.user_id);
                                    const response = await fetch(`/api/getInfluencerSocials?influencerId=${influencer.user_id}`, {
                                        credentials: 'include',
                                    });
                                    if (response.ok) {
                                        const data = await response.json();
                                        console.log('Socials fetched:', data);
                                        setSocials(data.data || []);

                                    } else {
                                        setSocials([]);
                                        console.error("Failed to fetch socials");
                                    }
                                    setSelectedInfluencerInfo(influencer);
                                    setInfoOpen(true);
                                } catch (error) {
                                    console.error('Error fetching socials:', error);
                                }
                            }}
                            sx={{ color: theme.palette.primary.main, ml: 1 }}
                        >
                            <InfoOutlinedIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
            {selectedInfluencerId && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6">
                        Send Offer to {influencers.find((inf) => inf.id === selectedInfluencerId)?.title}
                    </Typography>
                    <TextField
                        multiline
                        rows={4}
                        placeholder="Write your offer message here..."
                        value={offerMessage}
                        onChange={(e) => setOfferMessage(e.target.value)}
                        fullWidth
                        sx={{
                            mt: 2,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: theme.palette.text.primary,
                                },
                                '&:hover fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSendOffer}
                        sx={{ mt: 2 }}
                    >
                        Send Offer
                    </Button>
                </Box>
            )}
            <Dialog
                open={infoOpen}
                onClose={() => setInfoOpen(false)}
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    sx: {
                        backgroundColor: '#000',
                        border: '2px solid #EECA74',
                        borderRadius: '12px',
                    }
                }}
            >
                <DialogTitle sx={{ backgroundColor: '#000', color: '#EECA74' }}>Influencer Info</DialogTitle>
                <DialogContent dividers sx={{ backgroundColor: '#000' }}>
                {selectedInfluencerInfo && (
                        <Box sx={{ color: 'white' }}>
                            <Typography><strong>Name:</strong> {selectedInfluencerInfo.first_name} {selectedInfluencerInfo.last_name}</Typography>
                            <Typography><strong>Username:</strong> {selectedInfluencerInfo.username}</Typography>
                            <Typography><strong>Location:</strong> {selectedInfluencerInfo.location || 'N/A'}</Typography>
                            <Typography><strong>Area of Focus:</strong> {selectedInfluencerInfo.area_of_focus || 'N/A'}</Typography>
                            <Typography><strong>About:</strong> {selectedInfluencerInfo.about || 'N/A'}</Typography>
                            <Typography><strong>Tags:</strong> {selectedInfluencerInfo.tags?.join(', ') || 'None'}</Typography>
                            {Array.isArray(socials) && socials.length > 0 ? (
                                <>
                                    <Typography sx={{ mt: 2, color: '#EECA74' }}><strong>Social Accounts:</strong></Typography>
                                    {socials.map((account, index) => (
                                        <Box key={index} sx={{ ml: 1, mb: 1 }}>
                                            <Typography sx={{ color: 'white' }}>
                                                <strong>{account.platform}:</strong>{' '}
                                                <a
                                                    href={account.account_url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ color: '#EECA74' }}
                                                >
                                                    {account.username}
                                                </a>{' '}
                                                — {account.follower_count} followers
                                            </Typography>
                                        </Box>
                                    ))}
                                </>
                            ) : (
                                <Typography sx={{ mt: 2, color: '#888' }}>No social accounts found.</Typography>
                            )}
                        </Box>
                    )}
                </DialogContent>
            </Dialog>

        </Box>
    );
};

export default InfluencerSearch;
