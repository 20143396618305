import React, { useContext, useState, useEffect } from 'react';
import { supabase } from '../../supabase/supabaseClient';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getSession = async () => {
            const { data: { session }, error } = await supabase.auth.getSession();
            console.log("Session data:", session, "Error:", error); // Debugging
            if (session) {
                setCurrentUser(session.user);
            }
            setLoading(false); // Loading is done after the session check
        };

        getSession();

        const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
            console.log("Auth state changed:", event, session);
            setCurrentUser(session?.user ?? null);
            setLoading(false); // Set loading to false after auth state changes
        });

        return () => {
            authListener?.unsubscribe();
        };
    }, []);

    useEffect(() => {
        //console.log("Updated currentUser:", currentUser); // Debugging currentUser state
    }, [currentUser]);

    const value = { currentUser, setCurrentUser, loading, userLoggedIn: !!currentUser };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );

}
