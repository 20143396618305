import React, { useEffect, useState } from 'react';
import { Typography, Button, Box, Tabs, Tab } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CommonFrame from '../../components/CommonFrame';
import InfluencerSearchButton from '../../components/InfluencerSearchButton';
import JobPostingForm from '../../components/JobPostingForm';
import EditJobForm from '../job-manager/components/EditJobForm';
import JobApplicants from './JobApplicants';
import { useAuth } from '../../contexts/authContext';
import InfluencerSearch from "../../InfluencerSearch";

const JobManagerPage = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showApplicants, setShowApplicants] = useState(false);
  const [showInfluencerSearch, setShowInfluencerSearch] = useState(false);
  const [acceptedInfluencer, setAcceptedInfluencer] = useState(null);
  const [showEditJob, setShowEditJob] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [selectedTab, setSelectedTab] = useState('active'); // "active" or "completed"
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    fetchJobs();
  }, [currentUser]);

  const fetchJobs = async () => {
    try {
      const response = await fetch('/api/jobs', {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Failed to fetch jobs');
      let data = await response.json();
      // For each job, call the company completion status API and merge its data into the job object.
      data = await Promise.all(
          data.map(async (job) => {
            try {
              const compResponse = await fetch(
                  `/api/getCompletionStatusCompany?jobId=${job.id}`,
                  { credentials: 'include' }
              );
              if (compResponse.ok) {
                const compData = await compResponse.json();
                return {
                  ...job,
                  status: compData.data.status,
                  company_completed: compData.data.company_completed,
                };
              } else {
                return job;
              }
            } catch (error) {
              console.error(`Error fetching completion status for job ${job.id}:`, error);
              return job;
            }
          })
      );
      setJobs(data);
    } catch (e) {
      console.error("Fetching jobs failed:", e);
    }
  };

  const handleDeleteJob = async () => {
    if (!selectedJob) return;

    const confirmDelete = window.confirm("Are you sure you want to delete this job?");
    if (!confirmDelete) return;

    try {
      const res = await fetch('/api/deleteJob', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ jobId: selectedJob.id }),
      });

      const data = await res.json();

      if (res.ok) {
        alert("Job deleted successfully.");
        setJobs((prev) => prev.filter((job) => job.id !== selectedJob.id));
        handleBackToJobPosting();
      } else {
        alert(`Failed to delete job: ${data.message}`);
      }
    } catch (err) {
      console.error("Error deleting job:", err);
      alert("An error occurred while deleting the job.");
    }
  };


  useEffect(() => {
    if (selectedJob) {
      const fetchAcceptedInfluencer = async () => {
        try {
          const response = await fetch(`/api/getJobAcceptedBy?jobId=${selectedJob.id}`, {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
          });
          const data = await response.json();
          if (response.ok && data.success) {
            setAcceptedInfluencer(data.data);
          } else {
            console.error("Error fetching accepted influencer:", data.error);
            setAcceptedInfluencer(null);
          }
        } catch (error) {
          console.error("Error fetching accepted influencer:", error);
          setAcceptedInfluencer(null);
        }
      };

      fetchAcceptedInfluencer();
    } else {
      setAcceptedInfluencer(null);
    }
  }, [selectedJob]);

  const handleJobClick = (job) => {
    console.log('Selected Job:', job);
    setSelectedJob(job);
    setShowInfluencerSearch(false);
    setShowEditJob(false);
    setShowApplicants(false);
    setShowBackButton(true);
  };

  const handleEditJob = () => {
    setShowEditJob(true);
  };

  const handleShowApplicants = () => {
    setShowApplicants(true);
  };

  const handleBackToJobPosting = () => {
    setSelectedJob(null);
    setShowEditJob(false);
    setShowInfluencerSearch(false);
    setShowApplicants(false);
    setShowBackButton(false);
    setResetKey((prevKey) => prevKey + 1);
  };

  const handleCompleteJobForCompany = async () => {
    if (!selectedJob) {
      alert('Please select a job.');
      return;
    }
    console.log("selected job id in post job: ", selectedJob.id);
    setLoading(true);
    try {
      const response = await fetch('/api/completeJobForCompany', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ jobId: selectedJob.id }),
      });
      const data = await response.json();
      if (response.ok) {
        alert(data.message);
        // Update the local selected job status (assume company sets it to pending_completion)
        setSelectedJob({ ...selectedJob, status: 'pending_completion' });
        fetchJobs();
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error marking job as completed for company:', error);
      alert('Error marking job as completed for company.');
    } finally {
      setLoading(false);
    }
  };

  // Filter jobs based on the selected tab.
  const filteredJobs = jobs.filter((job) =>
      selectedTab === 'active' ? job.status !== 'completed' : job.status === 'completed'
  );

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedJob(null); // Clear selection when switching tabs
  };

  // Helper function to render the compensation info.
  const renderCompensation = () => {
    if (!selectedJob.compensation) return 'Not specified';
    const { type, value } = selectedJob.compensation;
    if (!type || !value) return 'Not specified';

    switch (type) {
      case 'both':
        return `Dollar + Other Compensation: ${value}`;
      case 'dollar':
        return `Dollar Compensation: ${value}`;
      case 'other':
        return `Other Compensation: ${value}`;
      default:
        return 'Not specified';
    }
  };

  // Always render the complete button when a job is selected.
  let showCompleteButton = selectedJob ? true : false;
  let completeButtonLabel = 'Mark as Completed';
  let isCompleteDisabled = false;
  if (selectedJob) {
    if (selectedJob.status === 'pending') {
      completeButtonLabel = 'Pending Acceptance';
      isCompleteDisabled = true;
    } else if (selectedJob.status === 'pending_completion') {
      if (selectedJob.company_completed) {
        completeButtonLabel = 'Pending Completion';
        isCompleteDisabled = true;
      } else {
        completeButtonLabel = 'Mark as Completed';
        isCompleteDisabled = false;
      }
    } else if (selectedJob.status === 'completed') {
      completeButtonLabel = 'Completed';
      isCompleteDisabled = true;
    } else {
      completeButtonLabel = 'Mark as Completed';
      isCompleteDisabled = false;
    }
  }

  // Define button styles.
  const activeButtonSx = { backgroundColor: '#EECA74', color: '#000000' };
  const disabledButtonSx = { backgroundColor: '#9e9e9e', color: '#fff', border: '1px solid #757575' };

  // Job details rendered in a table with gold identifiers.
  const jobDetailsUI = (
      <Box sx={{ mt: 2 }}>
        {selectedJob ? (
            <>
              <Typography variant="h6" textAlign="center">
                Selected Job: {selectedJob.title || 'Untitled Job'}
              </Typography>
              <Box sx={{ backgroundColor: 'black', p: 2, mt: 2, color: 'white' }}>
                <Box sx={{ overflowX: 'auto' }}>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tbody>
                    <tr>
                      <td style={{ color: '#EECA74', fontWeight: 'bold', padding: '8px' }}>
                        Description:
                      </td>
                      <td style={{ color: 'white', padding: '8px' }}>
                        {selectedJob.description || 'No description available'}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ color: '#EECA74', fontWeight: 'bold', padding: '8px' }}>
                        Location:
                      </td>
                      <td style={{ color: 'white', padding: '8px' }}>
                        {selectedJob.location || 'Not specified'}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ color: '#EECA74', fontWeight: 'bold', padding: '8px' }}>
                        Tags:
                      </td>
                      <td style={{ color: 'white', padding: '8px' }}>
                        {selectedJob.tags ? selectedJob.tags.join(', ') : 'No tags'}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ color: '#EECA74', fontWeight: 'bold', padding: '8px' }}>
                        Is Public:
                      </td>
                      <td style={{ color: 'white', padding: '8px' }}>
                        {selectedJob.is_public ? 'Yes' : 'No'}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ color: '#EECA74', fontWeight: 'bold', padding: '8px' }}>
                        Status:
                      </td>
                      <td style={{ color: 'white', padding: '8px' }}>
                        {selectedJob.status || 'Not specified'}
                      </td>
                    </tr>
                    {/* NEW CODE: Display Accepted Influencer */}
                    {acceptedInfluencer && (
                        <tr>
                          <td style={{ color: '#EECA74', fontWeight: 'bold', padding: '8px' }}>
                            Accepted by:
                          </td>
                          <td style={{ color: 'white', padding: '8px' }}>
                            {acceptedInfluencer.first_name} {acceptedInfluencer.last_name} ({acceptedInfluencer.username})
                          </td>
                        </tr>
                    )}
                    <tr>
                      <td style={{ color: '#EECA74', fontWeight: 'bold', padding: '8px' }}>
                        Date Posted:
                      </td>
                      <td style={{ color: 'white', padding: '8px' }}>
                        {selectedJob.date_posted ? new Date(selectedJob.date_posted).toLocaleString() : 'Unknown'}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ color: '#EECA74', fontWeight: 'bold', padding: '8px' }}>
                        Compensation:
                      </td>
                      <td style={{ color: 'white', padding: '8px' }}>
                        {renderCompensation()}
                      </td>
                    </tr>
                    {selectedJob.message && (
                        <tr>
                          <td style={{ color: '#EECA74', fontWeight: 'bold', padding: '8px' }}>
                            Message from Company:
                          </td>
                          <td style={{ color: 'white', padding: '8px' }}>
                            {selectedJob.message}
                          </td>
                        </tr>
                    )}
                    </tbody>
                  </table>
                </Box>
              </Box>
              <Box
                  sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2,
                    flexWrap: 'wrap'
                  }}
              >
                <Button variant="contained" color="primary" onClick={handleEditJob}>
                  Edit Job
                </Button>
                <Button variant="contained" color="secondary" onClick={handleShowApplicants}>
                  View Applications
                </Button>
                <InfluencerSearchButton
                    jobId={selectedJob.id}
                    onSearchClick={() => setShowInfluencerSearch(true)}
                />
                {showCompleteButton && (
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={handleCompleteJobForCompany}
                        disabled={isCompleteDisabled}
                        sx={{
                          ...(isCompleteDisabled ? disabledButtonSx : activeButtonSx),
                          '&.Mui-disabled': {
                            backgroundColor: '#9e9e9e',
                            color: '#fff',
                            border: '1px solid #757575',
                          },
                        }}
                    >
                      {loading ? 'Processing...' : completeButtonLabel}
                    </Button>
                )}
              </Box>
            </>
        ) : (
            <Typography textAlign="center">Please select a job to see details.</Typography>
        )}
      </Box>
  );

  return (
      <>
        {/* Tabs above the current frame */}
        <Box sx={{ mb: 2 }}>
          <Tabs value={selectedTab} onChange={handleTabChange} centered>
            <Tab label="Active" value="active" />
            <Tab label="Completed" value="completed" />
          </Tabs>
        </Box>

        <CommonFrame key={resetKey} items={filteredJobs} onSelectItem={handleJobClick}>
          {showBackButton && (
              <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <Button
                    variant="contained"
                    onClick={handleBackToJobPosting}
                    startIcon={<ArrowBackIcon />}
                >
                  Back to Job Posting
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteJob}
                >
                  Delete Job
                </Button>
              </Box>
          )}

          {!selectedJob && !showInfluencerSearch && !showEditJob && !showApplicants && (
              <JobPostingForm onJobPost={(newJob) => setJobs((prevJobs) => [...prevJobs, newJob])} />
          )}

          {selectedJob &&
              !showInfluencerSearch &&
              !showEditJob &&
              !showApplicants &&
              jobDetailsUI}

          {showEditJob && selectedJob && (
              <EditJobForm
                  selectedJob={selectedJob}
                  onSave={(updatedJob) => {
                    setJobs((prevJobs) =>
                        prevJobs.map((job) => (job.id === updatedJob.id ? updatedJob : job))
                    );
                    setSelectedJob(updatedJob);
                    setShowEditJob(false);
                  }}
                  onDelete={handleDeleteJob}
              />
          )}

          {showInfluencerSearch && selectedJob && (
              <InfluencerSearch
                  jobId={selectedJob.id}
                  onOfferSent={() => {
                    setShowInfluencerSearch(false);
                    setShowBackButton(true);
                  }}
              />
          )}

          {showApplicants && selectedJob && <JobApplicants jobId={selectedJob.id} />}
        </CommonFrame>
      </>
  );
};

export default JobManagerPage;
