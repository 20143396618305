import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import BusinessIcon from '@mui/icons-material/Business';
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

const data = {
  marketTrends: "Instagram Reels",
  engagementRates: "3.5%",
  audienceGrowth: "10% last month",
  revenueGrowth: "15% this quarter",
  campaignOpportunities: "5 new partnerships"
};

//const goldTextStyle = { color: '#EECA74' }; // Define a single style for all text

function InfluencerTrends() {
  const loading = false;

  return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginLeft: 2 }}>
        <DataBox title="Engagement Rates" content={data.engagementRates} loading={loading} icon={<ImportExportIcon />} index={0} />
        <DataBox title="Audience Growth" content={data.audienceGrowth} loading={loading} icon={<EmojiPeopleIcon />} index={1} />
        <DataBox title="Revenue Growth" content={data.revenueGrowth} loading={loading} icon={<BusinessCenterIcon />} index={2}/>
        <DataBox title="Campaign Opportunities" content={data.campaignOpportunities} loading={loading} icon={<BusinessIcon />} index={3} />
      </Box>
  );
}

function DataBox({ title, content, loading, icon, index }) {
  return (
      <Grow
          in={true}
          style={{ transformOrigin: '0 0 0' }}
          {...(index !== undefined ? { timeout: 1000 + index * 500 } : {})}
      >
        <Box sx={{
          position: 'relative',
          width: 310,
          height: 130,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          justifyContent: 'center',
          backgroundColor: loading ? '#D4D4D4' : '#000000',
          color: '#EECA74', // Default text color for the box
          boxShadow: 1,
          padding: 2,
          borderRadius: '16px',
          border: '2px solid #EECA74', // Gold border color
          overflow: 'hidden'
        }}>
          <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', marginBottom: 1}}>
            {icon} {title}
          </Typography>
          <Typography variant="body2" component="div" sx={{ fontWeight: 'bolder', fontSize: '25px'}}>
            {loading ? 'Loading...' : content}
          </Typography>
          <Typography variant="subtitle2" component="div" sx={{ fontWeight: 'light', fontSize: '10px'}}>
            {title === "Audience Growth" ? '12% last month' : ''}
          </Typography>

          {/* "Coming Soon" Overlay */}
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent black overlay
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#EECA74', // Gold text
            fontSize: '20px',
            fontWeight: 'bold',
          }}>
            Coming Soon
          </Box>
        </Box>
      </Grow>
  );
}

export default InfluencerTrends;
